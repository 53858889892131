import axios from "axios";
import jwt_decode from "jwt-decode";

import handleToken, { addBearer } from "./handleToken";
import handleCasing from "./handleCasing";
import handleTrailingSlash from "./handleTrailingSlash";

export const createClient = () => {
  const client = axios.create({
    timeout: 30 * 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  handleToken(client);
  handleCasing(client);
  handleTrailingSlash(client);

  return client;
};

const client = createClient();

client.interceptors.request.use(
  (request) => {
    let token = localStorage.getItem("jwt");
    if (!token) return request;
    token = localStorage.getItem("jwt");
    let { exp } = jwt_decode(token) || {};
    const now = new Date();
    const validToken = exp && exp * 1000 > now.getTime();
    if (validToken) {
      addBearer(request);
    }
    // Still return request since auth endpoints do not
    // need a token. A 401 will redirect.
    return request;
  },
  (error) => error
);

export default client;
