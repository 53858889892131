import React, { Component } from "react";
import { withState, compose } from "recompose";
import { withSnackbar } from "notistack";
import withRouter from "containers/WithRouter";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "actions/auth";

import validateIsEmail from "validator/lib/isEmail";

import CompleteSignUp from "components/CompleteSignUp";

class CompleteSignUpContainer extends Component {
  render() {
    const {
      login,
      enqueueSnackbar,
      newUser,
      setNewUser,
      createUser,
      setTerms,
      terms,
      history,
    } = this.props;
    const canSubmit =
      newUser.firstName &&
      newUser.lastName &&
      newUser.email &&
      validateIsEmail(newUser.email) &&
      newUser.password &&
      newUser.passwordMatch &&
      newUser.password === newUser.passwordMatch &&
      newUser.password.length >= 9 &&
      newUser.hasAcceptedTerms;
    return (
      <CompleteSignUp
        newUser={newUser}
        login={login}
        setNewUser={setNewUser}
        createUser={createUser}
        canSubmit={canSubmit}
        setTerms={setTerms}
        terms={terms}
        enqueueSnackbar={enqueueSnackbar}
        history={history}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(null, (dispatch) => bindActionCreators(authActions, dispatch)),
  withSnackbar,
  withState("newUser", "setNewUser", {}),
  withState("terms", "setTerms", false)
)(CompleteSignUpContainer);
