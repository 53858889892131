import React, { useState } from "react";

import LoadingOverlay from "components/LoadingOverlay";

// Delays showing the spinner until after a delay
// Avoids weird flashing pages that don't fully render
const Spinner = ({ delay = 500 }) => {
  const [ready, setReady] = useState(delay === 0);

  React.useEffect(() => {
    let timeout = null;
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  if (!ready) {
    return null;
  }
  return <LoadingOverlay />;
};

export default Spinner;
