import errors from './errors';
import faq from './faq';
import modal from './modal';
import user from './user';
import staff from './staff';

export default {
  errors,
  faq,
  modal,
  staff,
  user,
};
