import jwt_decode from "jwt-decode";
import humps from "humps";

import { DateTime } from "luxon";

import questions from "containers/ProfileCompletion/questions";

const getTokenData = () => {
  const token = localStorage.getItem("jwt");
  if (!token) {
    return undefined;
  }
  return humps.camelizeKeys(jwt_decode(token));
};

const getExpirationFromToken = (token) => {
  return token
    ? DateTime.fromSeconds(token.exp).toLocaleString(DateTime.TIME_WITH_SECONDS)
    : "expired";
};

const getUserTypeFromToken = (token) => {
  return token?.user_type;
};

const tokenOnLoad = getTokenData();

export default (
  state = {
    profile: {
      completedSections: [],
      percentDone: 0,
      userType: getUserTypeFromToken(tokenOnLoad),
    },
    role: {},
    settings: {},
    context: {
      cities: [],
    },
    languages: [
      {
        name: "",
        id: null,
        readingProficiency: "",
        writingProficiency: "",
        speakingProficiency: "",
      },
    ],
    activeQuestionIndex: 0,
    loginAttempts: 0,
    skills: [],
    token: tokenOnLoad,
    expiresAt: getExpirationFromToken(tokenOnLoad),
    loginError: false,
    takeProfileTour: false,
    questions: questions,
  },
  action
) => {
  switch (action.type) {
    case "AUTH/ADD_FAILED_LOGIN_ATTEMPT":
      return {
        ...state,
        loginAttempts: state.loginAttempts + 1,
      };
    case "AUTH/SET_USER_TOKEN":
      return {
        ...state,
        token: action.token,
        expiresAt: getExpirationFromToken(action.token),
      };
    case "AUTH/SET_TOKEN":
      return {
        ...state,
        token: action.token,
        expiresAt: getExpirationFromToken(action.token),
      };
    case "AUTH/POPULATE_BASIC_INFO": {
      const isEmployer = !!action.info?.employerId;

      const altLocations = (action.info || {}).alternativeLocations || [];
      const primaryLocations = (action.info || {}).primaryLocations || [];
      const secondaryLocations = (action.info || {}).secondaryLocations || [];
      const contractLocations = (action.info || {}).contractLocations || [];
      const referralType = action.info?.referralType || [];
      return {
        ...state,
        profile: {
          ...action.info,
          alternativeLocations: altLocations.map((c) => ({
            label: `${c.city}, ${c.state}`,
            value: c.id,
          })),
          primaryLocations: primaryLocations.map((c) => ({
            label: `${c.city}, ${c.state}`,
            value: c.id,
          })),
          secondaryLocations: secondaryLocations.map((c) => ({
            label: `${c.city}, ${c.state}`,
            value: c.id,
          })),
          contractLocations: contractLocations.map((c) => ({
            label: `${c.city}, ${c.state}`,
            value: c.id,
          })),
          referralType: isEmployer
            ? referralType.map((r) => ({ value: r, label: r }))
            : referralType,
        },
      };
    }
    case "PROFILE/START_PROFILE_TOUR":
      return {
        ...state,
        takeProfileTour: true,
      };
    case "PROFILE/END_PROFILE_TOUR":
      return {
        ...state,
        takeProfileTour: false,
      };
    case "PROFILE/UPDATE_VALUE": {
      const isEditingRole = window.location.pathname.includes("role/");

      if (isEditingRole) {
        return {
          ...state,
          dirty: true,
          role: {
            ...state.role,
            ...action.updates,
          },
        };
      }

      return {
        ...state,
        dirty: true,
        profile: {
          ...state.profile,
          ...action.updates,
        },
      };
    }
    case "SKILLS/POPULATE_SKILLS":
      return {
        ...state,
        skills: action.skills,
      };
    case "PROFILE/SET_UPDATES_SAVED":
      return {
        ...state,
        dirty: false,
      };
    case "PROFILE/POPULATE_CONTEXT":
      return {
        ...state,
        context: action.context,
      };
    case "PROFILE/POPULATE_SETTINGS":
      return {
        ...state,
        settings: action.settings,
      };
    case "PROFILE/POPULATE_ROLE":
      return {
        ...state,
        profile: {
          ...state.profile,
        },
        role: {
          // This should ideally be done on BE.
          // This makes accessing the keys easier
          // so that we don't have to pass a
          // `getKey` function
          ...action.role.educationPreferences,
          ...action.role.workExperiencePreferences,
          ...action.role,
        },
      };
    case "PROFILE/SET_ACTIVE_QUESTION_INDEX":
      return {
        ...state,
        activeQuestionIndex: action.newIndex,
      };
    case "PROFILE/SET_LANGUAGES":
      return {
        ...state,
        dirty: true,
        languages: action.languages,
      };
    default:
      return state;
  }
};
