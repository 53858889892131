import ReactGA from "react-ga";

const initGoogleAnalytics = (user) => {
  if (process.env.REACT_APP_ENDPOINT === "production") {
    ReactGA.initialize("UA-60342232-15", {
      titleCase: false,
      gaOptions: {
        testMode: process.env.NODE_ENV !== "production",
        userId: user || "GUEST",
        siteSpeedSampleRate: 10,
      },
    });
  }
};

export default initGoogleAnalytics;
