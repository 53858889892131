import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import sagaMiddleware from './saga';

const devTools = () => {
  if (process.env.REACT_APP_ENDPOINT !== 'production') {
    return composeWithDevTools(
      applyMiddleware(thunk, sagaMiddleware),
    );
  } else {
    return applyMiddleware(thunk, sagaMiddleware);
  }
};

export default devTools;
