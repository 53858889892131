export default (
  state = {
    faq: [],
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case 'FAQ/ADD_FAQ':
      return {
        ...state,
        faq: action.faq,
      };
    case 'FAQ/SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
