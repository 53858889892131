/**
 * This file is responsible for both
 * 1) sending the token with every response to the backend server
 * 2) refreshing the token if the expiration time is within 5 minutes or less.
 */
import jwt_decode from "jwt-decode";

import refreshToken from "./refreshToken";
import { DateTime } from "luxon";

export const addBearer = (request, force = false) => {
  const token = localStorage.getItem("jwt");
  if (
    !!token &&
    (!request || !request.headers || !request.headers.Authorization || force)
  ) {
    request.headers = {
      ...(request.headers || {}),
      Authorization: `Bearer ${token}`,
    };
  }
  return request;
};

export default function (client) {
  let token = localStorage.getItem("jwt");
  if (typeof document === "undefined" || !token) {
    return client;
  }

  client.interceptors.request.use(
    (request) => {
      token = localStorage.getItem("jwt");
      if (!token) return request;
      let { exp } = jwt_decode(token) || {};
      const date = new Date();
      const validToken = exp && exp * 1000 > date.getTime();
      if (validToken) {
        addBearer(request);
      }
      // Still return request since auth endpoints do not
      // need a token. A 401 will redirect.
      return request;
    },
    (error) => error
  );

  client.interceptors.response.use(
    async (response) => {
      token = localStorage.getItem("jwt");
      if (!token) return response;
      let { exp } = jwt_decode(token) || {};
      const date = new Date();
      const validToken = exp && exp * 1000 > date.getTime();
      if (
        token &&
        validToken &&
        DateTime.fromSeconds(exp) <= DateTime.local().plus({ minutes: 10 })
      ) {
        refreshToken();
      }
      return response;
    },
    (error) => {
      const errorDetails = error?.response?.data?.detail;
      const invalidToken =
        errorDetails === "Invalid signature." ||
        errorDetails === "Signature has expired.";
      if (invalidToken) {
        localStorage.removeItem("jwt");
      }
      return error;
    }
  );

  return client;
}
