export default (
  state = {
    errors: [],
  },
  action,
) => {
  switch (action.type) {
    case 'ADD_ERROR':
      return {
        ...state,
        errors: [...state.errors, action.error],
      };
    default:
      return state;
  }
};
