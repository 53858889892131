import React from "react";

import { Provider } from "react-redux";
import { useIsMobile } from "utils/cookbook";

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import LuxonAdapter from "@date-io/luxon";
import NoSsr from "@mui/material/NoSsr";
import ErrorBoundary from "containers/ErrorBoundary";
import LoginRefresh from "containers/LoginRefresh";
import TermsOfServiceUpdatedBanner from "containers/TermsOfServiceUpdatedBanner";
import Zoom from "@mui/material/Zoom";

import Routes from "routes";

import theme from "constants/theme";
import store from "store";

export default () => {
  const isMobile = useIsMobile();

  // @TODO add a setting for users to toggle this experimental feature on
  const prefersDarkMode = false; // useMediaQuery("(prefers-color-scheme: dark)");

  const userThemeByPreference = React.useMemo(
    () =>
      createTheme(
        prefersDarkMode
          ? {
              palette: {
                mode: prefersDarkMode ? "dark" : "light",
                primary: {
                  main: "#3b86b0",
                },
                secondary: {
                  main: "#2193b0",
                },
              },
            }
          : theme
      ),
    [prefersDarkMode]
  );

  return (
    <Provider store={store}>
      <NoSsr>
        <LocalizationProvider dateAdapter={LuxonAdapter}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: isMobile ? "top" : "bottom",
              horizontal: "right",
            }}
            TransitionComponent={Zoom}
          >
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={userThemeByPreference}>
                <CssBaseline />
                <ErrorBoundary>
                  <Routes />
                  <TermsOfServiceUpdatedBanner />
                  <LoginRefresh />
                </ErrorBoundary>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </NoSsr>
    </Provider>
  );
};
