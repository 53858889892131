export default function(client) {
  client.interceptors.request.use(config => {
    if (
      config.url[config.url.length - 1] !== "/" &&
      !config.url.includes("?")
    ) {
      config.url += "/";
    }
    return config;
  });

  return client;
}
