import { all, put, takeEvery } from 'redux-saga/effects';

import { retrieveUserInfo } from './login';
import { setUserToken } from 'actions/auth';

function* setToken(action) {
  const token = localStorage.getItem('jwt');
  yield put(setUserToken(token));
}

function* sagas() {
  yield all([setToken(), retrieveUserInfo()]);
  yield takeEvery('AUTH/SET_USER_TOKEN', retrieveUserInfo);
}

export default sagas;
