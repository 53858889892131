export const API = {
  local: "http://localhost:8000",
  development: "https://dev.workmonger.cloud",
  production: "https://prod.workmonger.cloud",
}[process.env.REACT_APP_ENDPOINT];

export const ADMIN_PORTAL = {
  local: "http://localhost:8000/wm-admin",
  development: "https://staging.workmonger.cloud/wm-admin",
  production: "https://prod.workmonger.cloud/wm-admin",
}[process.env.REACT_APP_ENDPOINT];
