export default {
  palette: {
    primary: {
      main: "#633C5B",
      light: "#6b777f",
      dark: "#1a242b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3b86b0",
      light: "#3b86b0",
      dark: "#3b86b0",
      contrastText: "#fff",
    },
    error: {
      active: "#ef5350",
      main: "#ef5350",
      light: "#ef5350",
      dark: "#ef5350",
      contrastText: "#fff",
      hover: "#ef5350",
      selected: "#ef5350",
    },
  },
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: "1px 3px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      textSecondary: {
        color: "#3b86b0",
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#2c3d49",
        },
      },
    },
    MuiFab: {
      textSecondary: {
        color: "#3b86b0",
      },
      styleOverrides: {
        primary: {
          backgroundColor: "#2c3d49",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#633C5B",
          "&.Mui-selected": {
            color: "#633C5B",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#633C5B",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#EAEAEA",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorSecondary: {
          color: "#084137",
        },
        mark: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          color: "#3b86b0",
          borderColor: "#3b86b0",
        },
        clickable: {
          "& :hover": {
            backgroundColor: "rgba(59, 134, 176, 0.05)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&$checked": {
            color: "#3b86b0",
          },
        },
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        position: "sticky",
        top: 14,
        left: 32,
        right: 32,
        borderTop: "2px solid #633C5B",
        borderBottom: "2px solid #633C5B",
        zIndex: 10000,
        borderRadius: 0,
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:after": {
            borderBottom: `2px solid #633C5B`,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 750,
      lg: 900,
      xl: 1536,
    },
  },
};
