import { useNavigate, useParams, useLocation } from "react-router-dom";

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const params = useParams();
    const location = useLocation();
    const history = useNavigate();

    return (
      <Component
        {...props}
        history={history}
        navigate={history}
        location={location}
        match={{ params }}
      />
    );
  };

  return Wrapper;
};

export default withRouter;
