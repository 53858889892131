import React, { Component } from "react";

import pluralize from "pluralize";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import withProfile from "HOC/withProfile";

import s from "./LoginRefresh.module.css";

class LoginRefresh extends Component {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }
  render() {
    const { token, retrieveNewToken } = this.props;

    if (!token || !token.exp) return null;

    const {
      token: { exp },
    } = this.props;
    const now = DateTime.local();
    const duration = DateTime.fromSeconds(exp)
      .diff(now, ["minutes", "seconds"])
      .toObject();
    const showInActivityMessage = duration.minutes <= 5 && duration.minutes > 0;

    return showInActivityMessage ? (
      <div className={s.banner}>
        To protect your account, you will be logged out in {duration.minutes}:
        {Math.round(duration.seconds).toString().padEnd(2, "0")}{" "}
        {pluralize("minute", duration.minutes)}. <br />
        <Button
          onClick={retrieveNewToken}
          className={s.stayLoggedInButton}
          variant="outlined"
        >
          {" "}
          Stay Logged In
        </Button>
      </div>
    ) : null;
  }
}

export default withProfile(LoginRefresh);
