import React, { useEffect, useState } from "react";
import { useUser, useActions } from "hooks";

import * as modalActions from "actions/modal";

import LoadingOverlay from "components/LoadingOverlay";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const { profile, token } = useUser();
  const { openModal } = useActions({
    ...modalActions,
  });
  const navigate = useNavigate();

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const checkRedirect = () => {
      if (profile?.user && profile?.userType === "jobseeker") {
        if (
          profile.migratedFromTypeform &&
          !profile.hasClaimedTypeformAccount
        ) {
          setShowConfetti(true);
        }
        openModal({
          variant: "REDIRECT_OPTIONS",
          canClose: false,
        });
      } else if (!token) {
        navigate("/login");
      } else {
        navigate("/dashboard");
      }
    };
    checkRedirect();
  }, [profile]);

  return showConfetti ? (
    <Confetti width={window.innerWidth} height={window.innerHeight} />
  ) : (
    <LoadingOverlay hideCircularProgress={profile?.user} />
  );
};

export default LandingPage;
