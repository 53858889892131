/**
 * This is the core of how questions are created.
 * Each object in this list can be thought of as a
 * container which passes props by key value. It also passes
 * the value that we will route to, the save function, any on loading
 * functions, etc.
 *
 * These are available in redux under `user`
 */
import * as options from "constants/options";

import { DateTime } from "luxon";
const dl = DateTime.local();

export default {
  intro_to_core: {
    key: "intro_to_core",
    back: null,
    next: null,
    variant: "modal",
    modalName: "INTRO_TO_CORE",
    canClose: false,
    action: null,
  },
  tour: {
    key: "tour",
    showCardlessNext: true,
    next: "job_seeking_status",
    variant: "tour",
    action: "updateUser",
  },
  job_seeking_status: {
    key: "jobSeekingStatus",
    next: "available_start_date",
    subSection: "General",
    variant: "radio",
    validate: (v) => v,
    question: "What is your job seeking status?",
    options: [
      { value: "active", label: "I am actively seeking my next job" },
      {
        value: "passive",
        label:
          "I am currently employed and happy, but if a great job came my way, I’d consider it",
      },
      {
        value: "part-time",
        label:
          "I am currently employed and am seeking a job to do in the evenings or on the weekends",
      },
      {
        value: "curious",
        label:
          "I’m not in a position to take a new job right now, but I’m curious what’s out there for down the road",
      },
    ],
    instructions: "Select from the options below",
    action: "updateUser",
    pieOffset: 0,
  },
  available_start_date: {
    key: "availableStartDate",
    back: "job_seeking_status",
    next: "seeking_hours_to_work",
    subSection: "General",
    variant: "radio",
    validate: (v) => v,
    question: "When would you consider starting a new job?",
    instructions: "Select from the options below",
    options: [
      {
        value: "immediately",
        label: "Immediately",
      },
      {
        value: "1 - 3 months",
        label: "Within 1 - 3 months",
        helperText: `Sometime in ${dl.plus({ months: 2 }).toFormat("LLLL")}`,
      },
      {
        value: "4 - 6 months",
        label: "Within 4 - 6 months",
        helperText: `Sometime in ${dl.plus({ months: 5 }).toFormat("LLLL")}`,
      },
      {
        value: "7 - 9 months",
        label: "Within 7 - 9 months",
        helperText: `Sometime in ${dl.plus({ months: 8 }).toFormat("LLLL")}`,
      },
      {
        value: "10 - 12 months",
        label: "Within 10 - 12 months",
        helperText: `Sometime in ${dl.plus({ months: 11 }).toFormat("LLLL")}`,
      },
      {
        value: "year plus",
        label: "More than a year from now",
      },
    ],
    action: "updateUser",
    pieOffset: 1,
  },
  seeking_hours_to_work: {
    key: "seekingHoursToWork",
    back: "available_start_date",
    next: "next_role_duration",
    subSection: "General",
    variant: "multi",
    instructions: (
      <>
        <p>
          Like other sectors, the education space is facing a rapidly changing
          talent market. We’re noticing that job searches are taking longer, and
          there are many more part time and consulting opportunities available.
        </p>
        <p>
          We would encourage you to be as open as possible when answering this
          question. Being open to part time or consulting work does NOT mean
          that you’re not eligible to be matched for full time roles as well. We
          can send both types of opportunities your way!
        </p>
      </>
    ),
    validate: (v) => v,
    question: "What hours of employment are you interested in?",
    options: options.roleTypes,
    action: "updateUser",
    pieOffset: 2,
  },
  next_role_duration: {
    key: "nextRoleDuration",
    back: "seeking_hours_to_work",
    next: "can_work_in_us",
    subSection: "General",
    variant: "multi",
    instructions: "Select Multiple Options from the list below",
    validate: (v) => v,
    question: "What length of employment are you interested in?",
    options: options.roleLengths,
    action: "updateUser",
    pieOffset: 3,
  },
  can_work_in_us: {
    key: "canWorkInUs",
    back: "next_role_duration",
    next: ({ canWorkInUs }) => {
      if (canWorkInUs === "true" || canWorkInUs === true) {
        return "can_work_in_us_in_the_future";
      }
      return "legal_explanation";
    },
    instructions: "Select from the options below",
    subSection: "General",
    variant: "radio",
    question: "Are you legally authorized to work in the United States?",
    options: options.booleanOptions,
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 4,
  },
  can_work_in_us_in_the_future: {
    key: "canWorkInUsInTheFuture",
    back: "can_work_in_us",
    next: ({ canWorkInUsInTheFuture }) => {
      if (
        canWorkInUsInTheFuture === "false" ||
        canWorkInUsInTheFuture === false
      ) {
        return "metro_area";
      }
      return "legal_explanation";
    },
    instructions: "Select from the options below",
    subSection: "General",
    variant: "radio",
    question:
      "Will you now or in the future require sponsorship for an employment visa?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    validate: (v) => v || v === false,
    action: "updateUser",
    pieOffset: 5,
  },
  legal_explanation: {
    key: "legalExplanation",
    back: ({ canWorkInUsInTheFuture }) => {
      if (
        canWorkInUsInTheFuture === "true" ||
        canWorkInUsInTheFuture === true
      ) {
        return "can_work_in_us_in_the_future";
      }
      return "can_work_in_us";
    },
    question: "Add an explanation about your legal status below",
    next: "metro_area",
    subSection: "General",
    variant: "legalStatus",
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 6,
  },
  metro_area: {
    key: "metroArea",
    closeOnSelect: true,
    back: ({ canWorkInUs, canWorkInUsInTheFuture }) => {
      if (canWorkInUs === "true" || canWorkInUs === true) {
        if (
          canWorkInUsInTheFuture === "true" ||
          canWorkInUsInTheFuture === true
        ) {
          return "legal_explanation";
        }
        return "can_work_in_us_in_the_future";
      }
      return "legal_explanation";
    },
    next: "openness_to_alternative_locations",
    subSection: "Location Preferences",
    question: "Select the Metro area where you currently reside",
    validate: (v) => v,
    transform: (c) => ({
      metroArea: c?.metroArea?.label,
    }),
    isValidNewOption: (inputValue) => {
      if (!inputValue || !inputValue.includes(",")) {
        return false;
      }
      const [rawCity, rawState] = inputValue.split(",");
      const city = rawCity.trim("");
      const state = rawState.trim("");

      if (city[0] !== city[0].toUpperCase()) {
        return false;
      }

      if (state.toUpperCase() !== state) {
        return false;
      }

      return true;
    },
    action: "updateUser",
    variant: "createableselect",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    options: (context) => context.cities,
    instructions: `Choose from the options below or -- if you don't live in one of the top 100 metro areas \
       -- add a custom city in the format {City, State Abbreviation}. In order to add a custom city, you will need to 
       capitalize your city and state abbreviation.`,
    isMulti: false,
    pieOffset: 7,
  },
  openness_to_alternative_locations: {
    key: "opennessToAlternativeLocations",
    back: "metro_area",
    next: ({ opennessToAlternativeLocations }) => {
      switch (opennessToAlternativeLocations) {
        case "anywhere":
          return "primary_locations";
        case "specific locations":
          return "outside_locations";
        case "remote and specific locations":
        case "home and specific locations":
          return "alternative_locations";
        default:
          return "compensation";
      }
    },
    subSection: "Location Preferences",
    variant: "radio",
    validate: (v) => v,
    question: "Where are you looking for work?",
    instructions: "Select from the options below",
    options: options.locationPreferences,
    action: "updateUser",
    pieOffset: 8,
  },
  primary_locations: {
    key: "primaryLocations",
    back: "openness_to_alternative_locations",
    next: "compensation",
    action: "updateGeoPreferences",
    isMulti: true,
    subSection: "Location Preferences",
    variant: "createableselect",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    validate: (v) => v,
    question:
      "We’re thrilled you’re willing to move wherever for the right opportunity! However, to make sure we’re best matching you, please let us know the cities you’d pack up and leave for tomorrow without hesitation. We've already noted down your current metro area so no need to select it",
    options: (context) => context.cities,
    instructions: "Select Multiple Options from the list below",
    onLoad: "fetchAllCities",
    pieOffset: 9,
  },
  outside_locations: {
    key: "alternativeLocations",
    back: "openness_to_alternative_locations",
    next: "compensation",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    action: "updateGeoPreferences",
    isMulti: true,
    subSection: "Location Preferences",
    variant: "createableselect",
    validate: (v) => v,
    question: "In what cities would you consider potential opportunities?",
    options: (context) => context.cities,
    instructions: "Select Multiple Options from the list below",
    placeholder: "Select the metro areas you would consider moving to?",
    onLoad: "fetchAllCities",
    pieOffset: 9,
  },
  alternative_locations: {
    key: "alternativeLocations",
    back: "openness_to_alternative_locations",
    next: "compensation",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    action: "updateGeoPreferences",
    isMulti: true,
    subSection: "Location Preferences",
    variant: "createableselect",
    validate: (v) => v,
    question:
      "In what cities would you consider potential opportunities? We've already noted down your current metro area so no need to select it",
    options: (context) => context.cities,
    instructions: "Select Multiple Options from the list below",
    placeholder: "Select the metro areas you would consider moving to?",
    onLoad: "fetchAllCities",
    pieOffset: 9,
  },
  compensation: {
    key: "compensation",
    back: ({ opennessToAlternativeLocations }) => {
      switch (opennessToAlternativeLocations) {
        case "anywhere":
          return "primary_locations";
        case "specific locations":
          return "outside_locations";
        case "home and specific locations":
          return "alternative_locations";
        default:
          return "openness_to_alternative_locations";
      }
    },
    next: ({ seekingHoursToWork }) => {
      const hasPartTimeWork = (seekingHoursToWork || []).filter(
        (typeOfWork) => typeOfWork !== "full-time"
      ).length;
      if (hasPartTimeWork) {
        return "rate_by_hour";
      }
      return "highest_education_level";
    },
    variant: "compensation",
    subSection: "Compensation",
    action: "updateCompensation",
    pieOffset: 10,
  },
  rate_by_hour: {
    key: "rate_by_hour",
    back: ({ seekingHoursToWork }) => {
      const hasFullTime = (seekingHoursToWork || []).includes("full-time");
      if (hasFullTime) {
        return "compensation";
      }
      return "openness_to_alternative_locations";
    },
    next: "highest_education_level",
    variant: "compensation",
    subSection: "Compensation",
    action: "updateCompensation",
    pieOffset: 10,
  },
  highest_education_level: {
    key: "highestEducationLevel",
    back: ({ seekingHoursToWork }) => {
      const hasPartTimeWork = (seekingHoursToWork || []).filter(
        (typeOfWork) => typeOfWork !== "full-time"
      ).length;
      if (hasPartTimeWork) {
        return "rate_by_hour";
      }
      const hasFullTime = (seekingHoursToWork || []).includes("full-time");
      if (hasFullTime) {
        return "compensation";
      }
      return "openness_to_alternative_locations";
    },
    next: ({ highestEducationLevel }) => {
      switch (highestEducationLevel) {
        case "Graduate":
        case "Undergraduate":
          return "education";
        default:
          return "years_full_time_experience";
      }
    },
    validate: (v) => v,
    action: "updateUser",
    variant: "radio",
    question:
      "What is the highest level of education you’ve attained or will have attained when you start your next role?",
    options: [
      {
        label: "High School Diploma or GED",
        value: "High School Diploma / GED",
      },
      { label: "Undergraduate", value: "Undergraduate" },
      { label: "Graduate", value: "Graduate" },
      { label: "None of these options apply", value: "N/A" },
    ],
    subSection: "Education",
    instructions: "Select from the options below",
    pieOffset: 10,
  },
  education: {
    key: "education",
    back: "highest_education_level",
    validate: (v) => v?.length,
    variant: "education",
    subSection: "Education",
    pieOffset: 11,
  },
  level_of_structure: {
    key: "levelOfStructure",
    back: "education",
    subSection: "Management Style",
    next: "has_managed",
    variant: "radio",
    instructions: "Select from the options below",
    question:
      "What management style do you best thrive in, get the most work accomplished, and feel most productive?",
    options: options.structureLevel,
    action: "updatePersonalWiring",
    pieOffset: 12,
  },
  has_managed: {
    key: "hasManaged",
    back: "level_of_structure",
    next: ({ hasManaged }) => {
      if (hasManaged === "true" || hasManaged === true) {
        return "years_managing_others";
      }
      return "teamwork_approach";
    },
    subSection: "Management Style",
    instructions: "Select from the options below",
    question:
      "During your work experience, have you had a role in which you directly supervised other people?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    variant: "radio",
    validate: (v) => v || v === false,
    action: "updateUser",
    pieOffset: 12,
  },
  years_managing_others: {
    key: "yearsManagingOthers",
    back: "has_managed",
    next: "managed_managers",
    instructions: "Select your years of experience using the slider below",
    max: 50,
    variant: "slider",
    validate: (v) => v,
    question: "How many years have you directly supervised other people?",
    subSection: "Management Style",
    action: "updateUser",
    pieOffset: 13,
  },
  managed_managers: {
    key: "managedManagers",
    back: "years_managing_others",
    next: "managed_num_of_employees",
    subSection: "Management Style",
    instructions: "Select from the options below",
    question:
      "During your work experience, have you had a role in which you directly supervised people who also had their own direct reports?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    variant: "radio",
    validate: (v) => v || v === false,
    action: "updateUser",
    pieOffset: 13,
  },
  managed_num_of_employees: {
    key: "managedNumOfEmployees",
    back: "managed_managers",
    next: "teamwork_approach",
    instructions: "Select from the options below",
    subSection: "Management Style",
    question:
      "What is the largest number of people you have ever supervised, including both directly (reported to you) and indirectly (reported to someone else who ultimately reported up the hierarchy to you)?",
    options: [
      "I’ve never managed people before",
      "1-2 people",
      "3-5 people",
      "6-10 people",
      "11-20 people",
      "21-40 people",
      "41-75 people",
      "76-100 people",
      "100+ people",
    ].map((v) => ({ value: v, label: v.replace("-", " - ") })),
    variant: "radio",
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 13,
  },
  teamwork_approach: {
    key: "teamworkApproach",
    back: ({ hasManaged }) => {
      if (hasManaged === "true" || hasManaged === true) {
        return "managed_num_of_employees";
      }
      return "has_managed";
    },
    subSection: "Teamwork Style",
    next: "deadline_approach",
    variant: "radio",
    instructions: "Select from the options below",
    question:
      "Which of these teamwork styles most closely approximates your desired approach to teamwork?",
    options: options.teamworkStyle,
    action: "updatePersonalWiring",
    pieOffset: 14,
  },
  deadline_approach: {
    key: "deadlineApproach",
    back: "teamwork_approach",
    next: "years_full_time_experience",
    subSection: "Teamwork Style",
    variant: "radio",
    instructions: "Select from the options below",
    question:
      "How would you respond to a deadline that was moved up (i.e. less time to complete a project)?",
    options: options.deadlineApproach,
    action: "updatePersonalWiring",
    pieOffset: 14,
  },
  years_full_time_experience: {
    key: "yearsFullTimeExperience",
    back: "deadline_approach",
    next: ({ yearsFullTimeExperience }) => {
      if (yearsFullTimeExperience === "first job") {
        return "functional_expertise_areas";
      }
      return "recent_experience";
    },
    instructions: "Select your years of experience using the slider below",
    max: 50,
    variant: "slider",
    validate: (v) => v,
    question: "How many years of full-time work experience do you have?",
    subSection: "Experience",
    action: "updateUser",
    pieOffset: 15,
  },
  recent_experience: {
    key: "recentExperience",
    question: "Tell Us About Your Most Recent Experience!",
    instructions: `Later in the fourth section, we'll ask you to fill in the rest of \
    your experience, but for now, just let us know about your most \
    recent or current role.`,
    back: "years_full_time_experience",
    subSection: "Experience",
    validate: (v) => v,
    variant: "recentEmployer",
    pieOffset: 16,
  },
  functional_expertise_areas: {
    key: "functionalExpertiseAreas",
    subSection: "Expertise",
    next: "skill_by_functional_expertise",
    back: "recent_experience",
    variant: "functionalAreas",
    validate: (v) => v?.filter((v) => v)?.length === 3,
    action: "updateUser",
    pieOffset: 17,
  },
  skill_by_functional_expertise: {
    key: "skillByFunctionalExpertise",
    back: "functional_expertise_areas",
    question: "Which skills in your expertise areas are you familiar with?",
    variant: "skills",
    next: "hopeful_functional_expertise_areas",
    subSection: "Skills",
    validate: (v) =>
      v && v.filter((s) => !s.deleted && s.skillYears && s.rating).length,
    pieOffset: 18,
  },
  hopeful_functional_expertise_areas: {
    key: "hopefulFunctionalExpertiseAreas",
    subSection: "Expertise",
    next: "resume",
    back: "skill_by_functional_expertise",
    variant: "functionalAreas",
    validate: (v) => v?.filter((v) => v)?.length === 3,
    action: "updateUser",
    pieOffset: 19,
  },
  resume: {
    key: "resume",
    question: "Upload Your Resume",
    instructions:
      "Uploading your resume helps us find opportunities that we think match your past experience. We'll never share your resume (or any information for that matter) with employers without your permission. (PDF format preferred, but not required.)",
    back: "hopeful_functional_expertise_areas",
    next: "wrap_up",
    variant: "resume",
    subSection: "Resume",
    validate: (v) => v,
    pieOffset: 19,
  },
  // intercept: {
  //   key: "incept",
  //   text: null,
  //   back: "resume",
  //   next: "wrap_up",
  //   variant: "resumeIntercept",
  //   subSection: "Resume",
  //   validate: (v) => v,
  //   pieOffset: 19,
  // },
  wrap_up: {
    key: ["phoneNumber", "linkedinUrl", "referralType", "customReferralType"],
    subSection: "Wrap Up",
    back: "resume",
    next: ({ gender }) => {
      if (gender) {
        return "contact_info";
      }
      return "intro_to_basics";
    },
    question: "Let's Wrap Up Core Profile",
    instructions: "Answering these is optional but helps us reach you easier",
    variant: "wrapUp",
    action: "saveCoreWrapUp",
    pieOffset: 20,
  },
  intro_to_basics: {
    key: "intro_to_basics",
    subSection: "Personal Info",
    variant: "modal",
    modalName: "INTRO_TO_BASICS",
    canClose: false,
    action: null,
    pieOffset: 0,
  },
  contact_info: {
    key: ["primaryAddress", "twitterHandle"],
    back: "wrap_up",
    question: "Contact Info",
    instructions:
      "All information is optional from this point forward, but the more you fill out, the better matches we can find for you",
    subSection: "Personal Info",
    variant: "contactInfo",
    action: "saveContactInfo",
    next: "gender",
    pieOffset: 1,
  },
  gender: {
    key: ["gender", "customGender"],
    back: "contact_info",
    next: "ethnicity",
    subSection: "Personal Info",
    variant: "gender",
    question: "Select your gender",
    instructions: "Select from the options below",
    options: [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
      {
        value: "Decline to State",
        label: "Decline to State",
      },
      {
        value: "I Identify in a Different Manner",
        label: "I Identify in a Different Manner",
      },
    ],
    action: "updateBaseUserFields",
    pieOffset: 2,
  },
  ethnicity: {
    key: "ethnicity",
    back: "gender",
    next: "workplace_location",
    subSection: "Personal Info",
    variant: "multi",
    question: "Select your ethnicity",
    instructions: "Select Multiple Options from the list below",
    options: [
      "Hispanic or Latino",
      "White (not Hispanic or Latino)",
      "Black or African-American",
      "Native Hawaiian or Pacific Islander",
      "Asian (not Hispanic or Latino)",
      "American Indian or Alaskan Native",
      "Declined to State",
    ].map((v) => ({ value: v, label: v })),
    action: "updateBaseUserFields",
    pieOffset: 3,
  },
  workplace_location: {
    key: "workplace_location",
    subSection: "Workplace Preferences",
    back: "ethnicity",
    next: "ideal_company_size",
    variant: "forceRank",
    question:
      "How likely are you to thrive in the following types of work structures?",
    instructions:
      "Please rank the following work structures according to your preference. Two options cannot share the same ranking.",
    options: [
      {
        text: "Virtual Work",
        helper: "How much do you prefer virtual work?",
        value: "virtualPreferenceScore",
      },
      {
        text: "Virtual Work with Flexibility",
        helper:
          "How important is virtual work with flexibility? You set your schedule.",
        value: "workFlexibilityPreference",
      },
      {
        text: "Mix of In Person & Remote",
        helper: "How preferable is a mix of in person and virtual work?",
        value: "hybridVirtualInPersonPreferenceScore",
      },
      {
        text: "In Person",
        helper: "How much do you prefer in person work?",
        value: "inPersonPreferenceScore",
      },
    ],
    action: "updateWorkPlacePreferences",
    pieOffset: 5,
  },
  ideal_company_size: {
    key: "idealCompanySize",
    subSection: "Workplace Preferences",
    instructions: "Select from the options below",
    back: "workplace_location",
    next: "ideal_growth_stage",
    variant: "radio",
    question: "What is your ideal size of employer?",
    options: ["No Preference", ...options.companySize].map((v) => ({
      value: v,
      label: v,
    })),
    action: "updateUser",
    pieOffset: 8,
  },
  ideal_growth_stage: {
    key: "idealGrowthStage",
    subSection: "Workplace Preferences",
    instructions: "Select from the options below",
    back: "ideal_company_size",
    next: "interested_in_specific_organization_type",
    variant: "radio",
    question: "What is your ideal growth stage of employer?",
    options: [
      "No Preference - I’m Flexible",
      "Start-up",
      "Early Growth",
      "Established",
      "Expansion",
      "Mature",
    ].map((v) => ({ value: v, label: v })),
    action: "updateUser",
    pieOffset: 10,
  },
  interested_in_specific_organization_type: {
    key: "interestedInSpecificOrganizationType",
    instructions: "Select Multiple Options from the list below",
    back: "ideal_growth_stage",
    next: "can_travel_overnight",
    subSection: "Workplace Preferences",
    variant: "multi",
    question:
      "Which types of education organizations are you interested in working for?",
    options: options.educationTypes.map((v) => ({ value: v, label: v })),
    action: "updateUser",
    pieOffset: 12,
  },
  can_travel_overnight: {
    key: "canTravelOvernight",
    instructions: "Select from the options below",
    subSection: "Travel Preferences",
    back: "interested_in_specific_organization_type",
    next: ({ canTravelOvernight }) => {
      if (canTravelOvernight === "true" || canTravelOvernight === true) {
        return "can_travel_how_much";
      }
      return "affiliations";
    },
    variant: "radio",
    question:
      "Some opportunities require overnight travel. Are you willing to travel overnight for work?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    action: "updateUser",
    pieOffset: 14,
  },
  can_travel_how_much: {
    key: "canTravelHowMuch",
    subSection: "Travel Preferences",
    instructions: "Select from the options below",
    back: "can_travel_overnight",
    next: "affiliations",
    variant: "radio",
    question: "How much would you be willing to travel?",
    options: options.travelAmounts,
    action: "updateUser",
    pieOffset: 16,
  },
  affiliations: {
    key: "affiliations",
    instructions: "Select Multiple Options from the list below",
    back: ({ canTravelOvernight }) => {
      if (canTravelOvernight || canTravelOvernight === "true") {
        return "can_travel_how_much";
      }
      return "can_travel_overnight";
    },
    next: () => "currently_sits_on_board",
    subSection: "Affiliations",
    variant: "multi",
    question: "Are you affiliated with any of the following organizations?",
    options: options.affiliations.map((v) => ({ value: v, label: v })),
    action: "updateUser",
    pieOffset: 18,
  },
  currently_sits_on_board: {
    key: "currentlySitsOnBoard",
    next: ({
      currentlySitsOnBoard,
      introducedSections,
      seekingHoursToWork,
    }) => {
      console.log({ currentlySitsOnBoard });
      if (currentlySitsOnBoard === true) {
        return "current_board_seats";
      }
      const hasPartTimeOrContractWorkSelected =
        options.partTimeOrContractOptions.find((opt) =>
          seekingHoursToWork?.includes(opt)
        );

      if (hasPartTimeOrContractWorkSelected) {
        return "part_time_intro";
      }

      if (!introducedSections?.includes("personal wiring")) {
        return "intro_to_personal_wiring";
      }
      return "methodology";
    },
    back: "affiliations",
    variant: "radio",
    question: "Do you currently sit on a Board of Directors or Advisory Board?",
    options: options.booleanOptions,
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 19,
  },
  current_board_seats: {
    key: "currentBoardSeats",
    placeholder: "Enter the board or boards separated by a comma",
    variant: "freetextarea",
    maxLength: 2000,
    next: () => "interested_in_board_roles",
    back: "currently_sits_on_board",
    question: "What boards do you sit on?",
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 19,
  },
  interested_in_board_roles: {
    key: "interestedInBoardRoles",
    next: ({ introducedSections, seekingHoursToWork }) => {
      const hasPartTimeOrContractWorkSelected =
        options.partTimeOrContractOptions.find((opt) =>
          seekingHoursToWork?.includes(opt)
        );

      if (hasPartTimeOrContractWorkSelected) {
        return "part_time_intro";
      }

      if (!introducedSections?.includes("personal wiring")) {
        return "intro_to_personal_wiring";
      }
      return "methodology";
    },
    back: "current_board_seats",
    options: options.booleanOptions,
    variant: "radio",
    question: "Are you interested in being matched for board opportunities?",
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 19,
  },
  part_time_intro: {
    key: "partTimeIntro",
    subSection: "Contract/Part-time",
    back: "affiliations",
    next: () => "special_education_organization_types_for_contract_work",
    variant: "simpleMessage",
    text: "Since you indicated you are open to part-time or consulting work, the next few questions will help us learn more about the types of part-time or consulting work you'd be open to.",
    pieOffset: 18,
  },
  special_education_organization_types_for_contract_work: {
    key: "specialEducationOrganizationTypesForContractWork",
    question:
      "Which types of education organizations are you interested in doing part-time or consulting work for?",
    instructions: "Select Multiple Options from the list below",
    back: "part_time_intro",
    subSection: "Contract/Part-time",
    variant: "multi",
    next: () => "contract_functional_areas",
    validate: (v) => v,
    options: options.educationTypes.map((v) => ({ value: v, label: v })),
    pieOffset: 19,
    action: "updateUser",
  },
  contract_functional_areas: {
    key: "contractFunctionalAreas",
    subSection: "Contract/Part-time",
    next: "contract_location_preferences_match_selected",
    back: "special_education_organization_types_for_contract_work",
    variant: "functionalAreas",
    validate: (v) => v?.filter((v) => v)?.length === 3,
    action: "updateUser",
    pieOffset: 19.5,
  },
  contract_location_preferences_match_selected: {
    key: "contractLocationPreferencesMatchSelected",
    back: "contract_functional_areas",
    instructions: "Select from the options below",
    subSection: "Contract/Part-time",
    next: ({ contractLocationPreferencesMatchSelected }) => {
      if (
        contractLocationPreferencesMatchSelected === true ||
        contractLocationPreferencesMatchSelected === "true"
      ) {
        return "part_time_summary";
      }
      return "openness_to_alternative_locations_for_contract_work";
    },
    variant: "radio",
    question:
      "Are your location preferences for part-time and consulting opportunities the same as what you selected for your full-time role?",
    options: options.booleanOptions,
    validate: (v) => v,
    action: "updateUser",
    pieOffset: 20,
  },
  openness_to_alternative_locations_for_contract_work: {
    key: "opennessToAlternativeLocationsForContractWork",
    back: "contract_functional_areas",
    next: ({ opennessToAlternativeLocationsForContractWork }) => {
      switch (opennessToAlternativeLocationsForContractWork) {
        case "anywhere":
        case "specific locations":
        case "remote and specific locations":
        case "home and specific locations":
          return "contract_locations";
        default:
          return "intro_to_personal_wiring";
      }
    },
    subSection: "Contract/Part-time",
    variant: "radio",
    validate: (v) => v,
    question: "Where are you looking for part-time or consulting work?",
    instructions: "Select from the options below",
    options: options.locationPreferences,
    action: "updateUser",
    pieOffset: 20,
  },
  contract_locations: {
    key: "contractLocations",
    back: "openness_to_alternative_locations_for_contract_work",
    next: "part_time_summary",
    createNewOptionText: "Add your own (include your state's abbreviation): ",
    action: "updateGeoPreferences",
    isMulti: true,
    subSection: "Contract/Part-time",
    variant: "createableselect",
    validate: (v) => v,
    question:
      "In what cities would you consider potential part-time or consulting opportunities outside of your current metro area?",
    options: (context) => context.cities,
    instructions: "Select Multiple Options from the list below",
    placeholder: "Select the metro areas you would consider moving to?",
    onLoad: "fetchAllCities",
    pieOffset: 21,
  },
  part_time_summary: {
    key: "partTimeSummary",
    subSection: "Contract/Part-time",
    back: ({ contractLocationPreferencesMatchSelected }) => {
      if (
        contractLocationPreferencesMatchSelected === true ||
        contractLocationPreferencesMatchSelected === "true"
      ) {
        return "contract_location_preferences_match_selected";
      }
      return "contract_locations";
    },
    next: ({ introducedSections }) => {
      if (!introducedSections?.includes("personal wiring")) {
        return "intro_to_personal_wiring";
      }
      return "methodology";
    },
    variant: "simpleMessage",
    text: "This concludes the questions about part-time/ consulting work",
    pieOffset: 21,
  },
  intro_to_personal_wiring: {
    key: "introToPersonalWiring",
    subSection: "Work Structure",
    variant: "modal",
    modalName: "INTRO_TO_PERSONAL_WIRING",
    canClose: false,
    action: null,
    pieOffset: 0,
  },
  methodology: {
    key: "methodology",
    subSection: "Work Structure",
    back: ({
      contractLocationPreferencesMatchSelected,
      seekingHoursToWork,
    }) => {
      const hasPartTimeOrContractWorkSelected =
        options.partTimeOrContractOptions.find((opt) =>
          seekingHoursToWork?.includes(opt)
        );

      if (hasPartTimeOrContractWorkSelected) {
        if (
          contractLocationPreferencesMatchSelected === true ||
          contractLocationPreferencesMatchSelected === "true"
        ) {
          return "contract_location_preferences_match_selected";
        }
        return "contract_locations";
      }

      return "affiliations";
    },
    question:
      "How likely are you to thrive in the following types of work structures?",
    instructions:
      "Please rank the following work structures according to your preference. Two options cannot share the same ranking.",
    next: "more_disciplined_than_flexible",
    variant: "forceRank",
    options: [
      {
        text: "Defined End and Means",
        helper:
          "Clear goals, deliverables, systems, processes, and steps are in place. Your job is to execute.",
        value: "prefersDefinedEndAndMeans",
      },
      {
        text: "Defined End, No Pre-determined means",
        helper:
          "Clear goal, deliverable, and metrics are in place. Your job is to determine how best to accomplish the goal and then execute your plan until the goals are successfully accomplished.",
        value: "prefersDefinedEndOnly",
      },
      {
        text: "Big Picture",
        helper:
          "In this situation, your role is to determine the end goal and corresponding metrics.",
        value: "prefersDevelopingBigPictureOnly",
      },
      {
        text: "Big Picture & Seeing the Project Through",
        helper:
          "In this situation, your job is to both determine the goal/metrics, and then develop and successfully execute a plan to achieve them.",
        value: "prefersDevelopingBigPictureAndExecuting",
      },
    ],
    action: "updateWorkPlaceMethodology",
    pieOffset: 6,
  },
  more_disciplined_than_flexible: {
    key: "moreDisciplinedThanFlexible",
    instructions: "Select the option that best matches your preference",
    subSection: "Personality",
    back: "methodology",
    next: "more_outgoing_than_introverted",
    variant: "preference",
    question: "I would consider myself more disciplined than flexible.",
    action: "updatePersonalWiring",
    leftOption: "More Flexible",
    rightOption: "More Disciplined",
    pieOffset: 10,
  },
  more_outgoing_than_introverted: {
    key: "moreOutgoingThanIntroverted",
    instructions: "Select the option that best matches your preference",
    subSection: "Personality",
    back: "more_disciplined_than_flexible",
    next: "culture_type",
    variant: "preference",
    question:
      "Do you prefer working collaboratively with others or working alone?",
    action: "updatePersonalWiring",
    leftOption: "Prefers to Work Alone",
    rightOption: "Prefers to Work in Social Environments",
    pieOffset: 12,
  },
  culture_type: {
    key: "cultureType",
    next: "personality_traits",
    back: "more_outgoing_than_introverted",
    variant: "cultureType",
    subSection: "Personality",
    helperText: "Select which box best describes your preferred culture.",
    question:
      "Which of the following culture types most closely reflects your preference?",
    action: "updatePersonalWiring",
    pieOffset: 14,
  },
  personality_traits: {
    key: "personalityTraits",
    subSection: "Personality",
    variant: "multi",
    instructions: "Select Multiple Options from the list below",
    helperText: "Pick up to 5 Options",
    back: "culture_type",
    next: ({ introducedSections, completedSections }) => {
      if (
        !introducedSections?.includes("work_experience") ||
        !completedSections?.includes("personal wiring")
      ) {
        return "intro_to_work_experience";
      }
      return "largest_budget_responsibility";
    },
    validate: (v) => v && v.length <= 5,
    error: (v) => (v && v.length > 5 ? "Only select 5 attributes" : null),
    question:
      "What are the top five attributes that you are looking for in the organizational culture in your next role?",
    options: options.personalityTraits.map((v) => ({ value: v, label: v })),
    action: "updatePersonalWiring",
    pieOffset: 14,
  },
  intro_to_work_experience: {
    key: "introToWorkExperience",
    variant: "modal",
    modalName: "INTRO_TO_WORK_EXPERIENCE",
    subSection: "Management Experience",
    canClose: false,
    action: null,
    pieOffset: 0,
  },
  largest_budget_responsibility: {
    key: "largestBudgetResponsibility",
    back: "personality_traits",
    subSection: "Management Experience",
    instructions: "Select from the options below",
    next: "experience",
    question:
      "What is the largest budget over which you have ever had management responsibility? ",
    options: [
      {
        value: "0",
        label: "I’ve never managed a budget before",
      },
      {
        value: "50000",
        label: "Less than $100k",
      },
      {
        value: "175000",
        label: "$100k - $250k",
      },
      {
        value: "375000",
        label: "$250k - $500k",
      },
      {
        value: "750000",
        label: "$500k - $999k",
      },
      {
        value: "2000000",
        label: "$1m - $2.99m",
      },
      {
        value: "4000000",
        label: "$3m - $4.9m",
      },
      {
        value: "7500000",
        label: "5m - $9.99m",
      },
      {
        value: "15000000",
        label: "$10m - $19.99m",
      },
      {
        value: "25000000",
        label: "$20m - $29.99m",
      },
      {
        value: "35000000",
        label: "$30m - $39.99m",
      },
      {
        value: "45000000",
        label: "$40m - $49.99m",
      },
      {
        value: "75000000",
        label: "$50m - $99.99m",
      },
      {
        value: "10000000",
        label: "$100 million or more",
      },
    ],
    variant: "radio",
    validate: (v) => v || v === 0,
    action: "updateUser",
    pieOffset: 5,
  },
  experience: {
    key: "experience",
    next: null,
    back: "largest_budget_responsibility",
    variant: "experience",
    subSection: "Work Experience",
    pieOffset: 15,
  },
  intro_to_skills: {
    key: "introToSkills",
    variant: "modal",
    subSection: "Languages",
    modalName: "INTRO_TO_SKILLS",
    canClose: false,
    action: null,
    pieOffset: 0,
  },
  languages: {
    key: "languages",
    back: "experience",
    next: null,
    subSection: "Languages",
    variant: "languages",
    pieOffset: 2,
  },
  skills_menu: {
    key: "skills_menu",
    back: "languages",
    showCardlessNext: true,
    next: "profile_overview",
    subSection: "Other Skills",
    variant: "skillsMenu",
    pieOffset: 10,
  },
  profile_overview: {
    key: "profile_overview",
    back: "skills_menu",
    variant: "modal",
    modalName: "PROFILE_OVERVIEW",
    canClose: false,
    action: null,
  },
  me: {
    key: "me",
    variant: "me",
  },
  map: {
    key: "map",
    variant: "map",
    pieOffset: 0,
  },
  default: {
    key: "default",
    back: "languages",
    next: null,
    subSection: "Languages",
    variant: "sink",
  },
};
