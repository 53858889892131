import * as Sentry from "@sentry/browser";

const initSentry = () => {
  if (process.env.NODE_ENV === "local") {
    return null;
  }

  Sentry.init({
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      "Cannot call setIn() with null state",
    ],
    dsn: "https://ef5138f9f19c4bf0b1a062d6b3a3df88@sentry.io/1475719",
    environment: process.env.NODE_ENV,
  });
};

export default initSentry;
