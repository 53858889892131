import axios, { createClient } from "utils/axios";
import jwt_decode from "jwt-decode";
import { API } from "config";

import { addError } from "./errorHandling";

export const startTour = () => ({
  type: "PROFILE/START_PROFILE_TOUR",
});

export const endTour = () => ({
  type: "PROFILE/END_PROFILE_TOUR",
});

export const updateProfile = (updates) => ({
  type: "PROFILE/UPDATE_VALUE",
  updates,
});

export const setUpdatesSaved = () => ({
  type: "PROFILE/SET_UPDATES_SAVED",
});

export const populateContext = (context) => ({
  type: "PROFILE/POPULATE_CONTEXT",
  context,
});

export const populateProfile = (info) => ({
  type: "AUTH/POPULATE_BASIC_INFO",
  info,
});

export const populateSkills = (skills) => ({
  type: "SKILLS/POPULATE_SKILLS",
  skills,
});

export const populateSettings = (settings) => ({
  type: "PROFILE/POPULATE_SETTINGS",
  settings,
});

export const populateRole = (role) => ({
  type: "PROFILE/POPULATE_ROLE",
  role,
});

export const setActiveQuestionIndex = (newIndex) => ({
  type: "PROFILE/SET_ACTIVE_QUESTION_INDEX",
  newIndex,
});

export const setLanguages = (languages) => ({
  type: "PROFILE/SET_LANGUAGES",
  languages,
});

export const patchProfileCompletion =
  ({ userId, updates }) =>
  async (dispatch) => {
    const { status } = await axios.patch(
      `${API}/api/profile-status/${userId}/`,
      updates
    );
    if (status === 200) {
      dispatch(retrieveProfile(userId));
    }
  };

export const updateUser =
  ({ userId, updates, forceUpdate = false }) =>
  async (dispatch) => {
    const res = await axios.patch(`${API}/api/profile/${userId}/`, updates);
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
      if (forceUpdate) dispatch(retrieveProfile(userId));
    }
    return res;
  };

export const updatePersonalWiring =
  ({ userId, updates }) =>
  async (dispatch) => {
    const res = await axios.patch(
      `${API}/api/personal-wiring/${userId}/`,
      updates
    );
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
    }
    return res;
  };

export const updateWorkPlacePreferences =
  ({ userId, updates }) =>
  async (dispatch, getState) => {
    const {
      virtualPreferenceScore,
      workFlexibilityPreference,
      hybridVirtualInPersonPreferenceScore,
      inPersonPreferenceScore,
    } = getState().user.profile;
    const res = await axios.patch(`${API}/api/profile/${userId}/`, {
      virtualPreferenceScore,
      workFlexibilityPreference,
      hybridVirtualInPersonPreferenceScore,
      inPersonPreferenceScore,
    });
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
    }
    return res;
  };

export const updateWorkPlaceMethodology =
  ({ userId, updates }) =>
  async (dispatch, getState) => {
    const {
      prefersDefinedEndAndMeans,
      prefersDefinedEndOnly,
      prefersDevelopingBigPictureOnly,
      prefersDevelopingBigPictureAndExecuting,
    } = getState().user.profile;
    const res = await axios.patch(`${API}/api/personal-wiring/${userId}/`, {
      prefersDefinedEndAndMeans,
      prefersDefinedEndOnly,
      prefersDevelopingBigPictureOnly,
      prefersDevelopingBigPictureAndExecuting,
    });
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
    }
    return res;
  };

export const markSkillsDone =
  ({ userId }) =>
  (dispatch) =>
    dispatch(
      patchProfileCompletion({
        patchProfileCompletion: userId,
        updates: {
          completed_skills: true,
        },
      })
    );

export const resendConfirmationEmail = (userId) => () =>
  axios.get(`${API}/resend-confirmation-email/`);

export const updateBaseUserFields =
  ({ userId, updates, doUpdateProfile = false }) =>
  async (dispatch) => {
    const res = await axios.patch(`${API}/api/user/${userId}/`, updates);
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
      if (doUpdateProfile) {
        dispatch(retrieveProfile(userId));
      }
    }
    return res;
  };

export const updateGeoPreferences =
  ({ userId, updates }) =>
  async (dispatch, getState) => {
    const {
      user: {
        context: { cities },
      },
    } = getState();

    const keyName = Object.keys(updates)[0];
    const existingCities = cities.map(({ value }) => value);
    const nonExistingCities = updates[keyName]
      ?.filter(({ value }) => !existingCities?.includes(value))
      .filter((a) => a.value.includes(","))
      .map((c) =>
        axios.post(`${API}/api/cities/`, {
          city: c.value.split(",")[0],
          state: c.value.split(",")[1],
        })
      );

    const citiesWithIds = updates[keyName]
      ?.filter(({ value }) => existingCities.includes(value))
      .map(({ value }) => value);

    const results = await Promise.all(nonExistingCities);
    const newlyCreatedIds = results
      .map(({ data }) => data?.id)
      .filter((id) => id);
    const combinedUpdate = [
      ...(citiesWithIds || []),
      ...(newlyCreatedIds || []),
    ];

    const updateByKeys = {
      ...updates,
      [keyName]: combinedUpdate,
    };
    const res = await axios.patch(
      `${API}/api/geographic-preferences/${userId}/`,
      updateByKeys
    );
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
    }
    return res;
  };

export const fetchContext = () => async (dispatch) => {
  try {
    const [cityResponse, expertiseAreaResponse] = await Promise.all([
      axios.get(`${API}/api/cities/`, {
        params: {
          limit: 1000,
        },
      }),
      axios.get(`${API}/api/expertise-areas`),
    ]);
    if (cityResponse.status === 200) {
      dispatch(
        populateContext({
          cities: cityResponse.data.results.map((c) => ({
            label: `${c.city}, ${c.state}`,
            value: c.id,
          })),
          expertiseAreas: expertiseAreaResponse.data,
        })
      );
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile",
      })
    );
  }
};

export const updateCompensation =
  ({ userId, updates }) =>
  async (dispatch, getState) => {
    const compensation = getState().user.profile.compensation;
    const res = await axios.patch(
      `${API}/api/compensation/${userId}`,
      updates.compensation || compensation
    );
    if (res.status === 200) {
      dispatch(setUpdatesSaved());
    }
    return res;
  };

export const searchSchools = (school) => () =>
  axios.get(`${API}/api/universities/?search=${school}&page=1`);

export const retrieveProfile = (userId) => async (dispatch) => {
  const token = localStorage.getItem("jwt");
  const client = createClient();
  const res = await client.get(`${API}/api/my-profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) dispatch(populateProfile(res.data || {}));
};

export const simpleProfileRetrieval = (userId) => async () => {
  const token = localStorage.getItem("jwt");
  const client = createClient();
  const res = await client.get(`${API}/api/my-profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const addEducationalRecord =
  (record, enqueueSnackbar) => async (dispatch) => {
    const res = await axios.post(`${API}/api/education/`, record);
    if (res.status === 201) {
      dispatch(retrieveProfile(record.user));
      enqueueSnackbar("New Educational Record Added", {
        variant: "success",
      });
    }
  };

export const editEducationalRecord =
  (record, enqueueSnackbar) => async (dispatch) => {
    const res = await axios.patch(`${API}/api/education/${record.id}`, record);
    if (res.status === 200) {
      dispatch(retrieveProfile(record.user));
      enqueueSnackbar("Record Updated", {
        variant: "success",
      });
    }
  };

export const removeSchoolRecord = (record) => async (dispatch) => {
  const res = await axios.delete(`${API}/api/education/${record.id}`);
  if (res.status === 204) {
    dispatch(retrieveProfile(record.userId));
  }
};

export const updateEmployer = (employer, user) => async (dispatch) => {
  try {
    const { status } = await axios.patch(
      `${API}/api/employers/${employer.employerId}`,
      employer
    );

    if (status === 200 && user) {
      dispatch(retrieveProfile(user));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.updateEmployer",
      })
    );
  }
};

export const sendContract =
  ({ employerId, emailAddress, firstName }) =>
  async (dispatch) =>
    await axios.post(`${API}/api/employer/contract/`, {
      employerId,
      emailAddress,
      firstName,
    });

export const updateEmployee =
  ({ updates, employeeId, user }) =>
  async (dispatch) => {
    try {
      const { status } = await axios.patch(
        `${API}/api/employer/staff/${employeeId}`,
        updates
      );

      if (status === 200) {
        dispatch(retrieveProfile(user));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.updateEmployee",
        })
      );
    }
  };

export const updateRole = (updates) => async (dispatch) => {
  try {
    const { status } = await axios.patch(
      `${API}/api/employer/role/${updates.roleUuid}`,
      updates
    );

    if (status === 200) {
      dispatch(fetchRole(updates.roleUuid));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.updateEmployer",
      })
    );
  }
};

export const deleteRole = (roleUuid) => async (dispatch) =>
  axios.delete(`${API}/api/employer/role/${roleUuid}`);

export const updateEducationPreferences = (updates) => async (dispatch) => {
  try {
    const { status } = await axios.patch(
      `${API}/api/employer/education-preferences/${updates.educationPreferencesId}`,
      updates
    );

    if (status === 200) {
      dispatch(fetchRole(updates.roleUuid));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.updateEducationPreferences",
      })
    );
  }
};

export const updateWorkExperiencePreferences =
  (updates) => async (dispatch) => {
    try {
      const { status } = await axios.patch(
        `${API}/api/employer/work-experience-preferences/${updates.workExperiencePreferencesId}`,
        updates
      );

      if (status === 200) {
        dispatch(fetchRole(updates.roleUuid));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.updateWorkExperiencePreferences",
        })
      );
    }
  };

export const createRole = (employer, user, history) => async (dispatch) => {
  try {
    const { data, status } = await axios.post(`${API}/api/employer/role`, {
      employer: employer.employerId,
    });

    if (status === 201) {
      const roleUUID = data.uuid;
      history(`/role/${roleUUID}`);
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.createRole",
      })
    );
  }
};

export const cloneRole = (role, history) => async (dispatch) => {
  try {
    history("/loading");
    const { data, status } = await axios.post(`${API}/api/employer/role`, {
      ...role,
      educationPreferences: null,
      workExperiencePreferences: null,
      serviceTierSelection: null,
      jobFileUpload: null,
      idealStartDate: null,
      latestStartDate: null,
      status: "draft",
      availableMetroAreas: role.availableMetroAreas?.map((area) => area.value),
      clonedFrom: role.id,
    });

    if (status === 201) {
      const roleUUID = data.uuid;
      history(`/role/${roleUUID}/job_title`);
    }
  } catch (e) {
    history("/");
    dispatch(
      addError({
        message: e.message,
        module: "profile.cloneRole",
      })
    );
  }
};

export const fetchRole = (roleUuid) => async (dispatch) => {
  try {
    const { data, status } = await axios.get(
      `${API}/api/employer/role/${roleUuid}`
    );

    if (status === 200) {
      dispatch(populateRole(data));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.cloneRole",
      })
    );
  }
};

export const updateEmployerReferralType =
  (employer, user) => async (dispatch) => {
    try {
      const { status } = await axios.patch(
        `${API}/api/employers/${employer.employerId}`,
        {
          referralType: employer?.referralType.map((r) => r.value),
        }
      );

      if (status === 200) {
        dispatch(retrieveProfile(user));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.updateEmployer",
        })
      );
    }
  };

export const updateEmployerLogos =
  (fileFormData, employerId, user) => async (dispatch) => {
    try {
      const { status } = await axios.patch(
        `${API}/api/employers/${employerId}`,
        fileFormData
      );

      if (status === 200) {
        dispatch(retrieveProfile(user));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.updateEmployer",
        })
      );
    }
  };

export const addFeedback = (feedback) => async (dispatch) => {
  try {
    await axios.post(`${API}/api/employer/role-feedback/`, feedback);
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.addFeedback",
      })
    );
  }
};

export const updateFeedback = (feedback) => async (dispatch) => {
  try {
    await axios.patch(
      `${API}/api/employer/role-feedback/${feedback.id}`,
      feedback
    );
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.updateFeedack",
      })
    );
  }
};

export const upsertLanguageRequirement = () => async (dispatch, getState) => {
  const { languages, role: { id: roleId } = {} } = getState().user;

  return languages
    .filter((l) => l?.name)
    .map((l) => ({ ...l, name: l?.name?.label || l?.name, role: roleId }))
    .map((language) => {
      if (language.id) {
        return axios.patch(
          `${API}/api/employer/language-requirement/${language.id}`,
          language
        );
      }
      return axios.post(`${API}/api/employer/language-requirement/`, language);
    });
};

export const deleteLanguageRequirement = (languageId) => async (dispatch) =>
  axios.delete(`${API}/api/employer/language-requirement/${languageId}`);

export const createExpertiseSkill = (updates) => async (dispatch) => {
  try {
    await axios.post(`${API}/api/employer/expertise-skill`, updates);
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.createExpertiseSkill",
      })
    );
  }
};

export const editExpertiseSkill = (updates) => async (dispatch) => {
  try {
    await axios.patch(
      `${API}/api/employer/expertise-skill/${updates.id}`,
      updates
    );
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.editExpertiseSkill",
      })
    );
  }
};

export const deleteExpertiseSkill = (updates) => async (dispatch) => {
  try {
    await axios.delete(`${API}/api/employer/expertise-skill/${updates.id}`);
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.deleteExpertiseSkill",
      })
    );
  }
};

export const createRecentEmployer =
  ({ user, updates }) =>
  async (dispatch) => {
    const {
      name,
      jobTitle,
      relatedToEducation,
      educationType,
      currentlyAtJob,
      startDate,
      endDate,
      mostRecentRole,
    } = updates;

    try {
      const workExperienceUpdate = {
        name: name?.value || name,
        relatedToEducation,
        educationType: educationType ? educationType.value : null,
        mostRecentRole,
        jobTitle: jobTitle?.value || jobTitle,
        currentlyAtJob,
        startDate,
        endDate: currentlyAtJob ? null : endDate,
        user,
      };

      await axios.post(`${API}/api/my-experience/`, workExperienceUpdate);
      dispatch(retrieveProfile(user));

      return { success: true };
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile",
        })
      );
    }
    return { success: false };
  };

export const updateRecentExperience =
  ({ user, updates }) =>
  async (dispatch) => {
    const {
      name,
      jobTitle,
      relatedToEducation,
      educationType,
      currentlyAtJob,
      startDate,
      endDate,
      mostRecentRole,
      employerId,
      id: workExperienceId,
    } = updates;

    try {
      await axios.patch(`${API}/api/my-experience/`, {
        workExperienceId,
        mostRecentRole,
        employerId,
        name: name?.value || name,
        relatedToEducation,
        educationType: educationType?.value,
        jobTitle: jobTitle?.value || jobTitle,
        user,
        currentlyAtJob,
        startDate,
        endDate,
      });
      dispatch(retrieveProfile(user));
      return { success: true };
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile",
        })
      );
    }
    return { success: false };
  };

export const removeWorkExperience = (record) => async (dispatch) => {
  const res = await axios.delete(`${API}/api/work-experience/${record.id}`);
  if (res.status === 204) {
    dispatch(retrieveProfile(record.user));
  }
};

export const createSkill =
  ({ updates }) =>
  async (dispatch) => {
    try {
      const res = await axios.post(`${API}/api/skills`, updates);
      if (res.status === 201) {
        dispatch(retrieveProfile(updates.user));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.skill",
        })
      );
    }
  };

export const editSkill =
  ({ updates }) =>
  async (dispatch) => {
    try {
      const res = await axios.patch(
        `${API}/api/skills/${updates.skillId}`,
        updates
      );
      if (res.status === 200) {
        dispatch(retrieveProfile(updates.user));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.skill",
        })
      );
    }
  };

export const uploadResume = (resume, user) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API}/api/profile/${user}/`, resume);
    if (res.status === 200) {
      dispatch(retrieveProfile(user));
    }
    return res;
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.resume",
      })
    );
  }
  return {};
};

export const uploadJobDescription =
  (jobDescription, roleUuid) => async (dispatch) => {
    let res;
    try {
      res = await axios.patch(
        `${API}/api/employer/role/${roleUuid}`,
        jobDescription
      );
      if (res.status === 200) {
        dispatch(fetchRole(roleUuid));
      }
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.jobDescriptionUpload",
        })
      );
    }
    return {};
  };

export const saveCoreWrapUp =
  ({ userId, updates }) =>
  async (dispatch) => {
    try {
      await axios.patch(`${API}/api/user/${userId}/`, {
        phoneNumber: updates.phoneNumber
          ? updates.phoneNumber
              .replace(/\(/g, "")
              .replace(/\)/g, "")
              .replace(/-/g, "")
              .replace(/\s/g, "")
          : null,
      });
      await axios.patch(`${API}/api/profile/${userId}/`, {
        referralType: updates.referralType.map(({ value }) => value),
        customReferralType: updates.customReferralType,
      });
      const res = await axios.patch(`${API}/api/social-accounts/${userId}/`, {
        linkedinUrl: updates.linkedinUrl,
      });
      if (res.status === 200) {
        dispatch(setUpdatesSaved());
      }
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.coreWrapUp",
        })
      );
    }
  };

export const saveEmployerAddress =
  ({ ...updates }) =>
  async (dispatch) => {
    try {
      const address = updates.primaryAddress;
      const method = address?.id ? "patch" : "post";
      const endpoint = address?.id
        ? `${API}/api/user-address/${address.id}`
        : `${API}/api/user-address/`;
      const res = await axios[method](endpoint, {
        primary: true,
        streetAddress: address.streetAddress,
        zipcode: address.zipcode,
        city: address.city,
        state: address.state.value,
      });
      if (res.status === 200 || res.status === 201) {
        dispatch(setUpdatesSaved());
        dispatch(retrieveProfile());
      }
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.contactInfo",
        })
      );
    }
  };

export const saveContactInfo =
  ({ userId, updates }) =>
  async (dispatch) => {
    try {
      let res;
      await axios.patch(`${API}/api/social-accounts/${userId}/`, {
        twitterHandle: updates.twitterHandle,
      });
      if (updates.primaryAddress.uuid) {
        const address = updates.primaryAddress;
        res = await axios.patch(`${API}/api/user-address/${address.id}`, {
          primary: true,
          streetAddress: address.streetAddress,
          zipcode: address.zipcode,
          city: address.city,
          state: address.state.value,
          user: [userId],
        });
      } else {
        const address = updates.primaryAddress;
        res = await axios.post(`${API}/api/user-address/`, {
          primary: true,
          streetAddress: address.streetAddress,
          zipcode: address.zipcode,
          city: address.city,
          state: address.state.value,
          user: [userId],
        });
      }
      if (res.status === 200 || res.status === 201) {
        dispatch(setUpdatesSaved());
        dispatch(retrieveProfile());
      }
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.contactInfo",
        })
      );
    }
  };

export const saveLanguage =
  ({ userId, updates }) =>
  async (dispatch) => {
    try {
      let res;
      if (updates.id) {
        res = await axios.patch(`${API}/api/language/${updates.id}`, updates);
      } else {
        res = await axios.post(`${API}/api/language`, updates);
      }
      dispatch(retrieveProfile(userId));
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.languages",
        })
      );
    }
    return {};
  };

export const deleteLanguage =
  ({ userId, languageId }) =>
  async (dispatch) => {
    try {
      axios.delete(`${API}/api/language/${languageId}`);
      dispatch(retrieveProfile(userId));
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.languages",
        })
      );
    }
  };

export const retrieveUserSkills = (userId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${API}/api/skills/${userId}`);
    dispatch(populateSkills(data));
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.retrievingSkills",
      })
    );
  }
};

export const saveSkill =
  ({ userId, updates, shouldUpdate }) =>
  async (dispatch) => {
    try {
      let res;
      if (!updates.uuid) {
        res = await axios.post(`${API}/api/skills`, updates);
      } else {
        res = await axios.patch(`${API}/api/skills/${updates.uuid}`, updates);
      }
      if (res.status === 201 || (res.status === 200 && shouldUpdate)) {
        dispatch(retrieveUserSkills(userId));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.addingSkills",
        })
      );
    }
  };

export const saveSkillRequirement =
  ({ updates }) =>
  async (dispatch) => {
    try {
      let res;
      if (!updates.uuid) {
        res = await axios.post(
          `${API}/api/employer/skill-requirements/`,
          updates
        );
      } else {
        res = await axios.patch(
          `${API}/api/employer/skill-requirements/${updates.uuid}`,
          updates
        );
      }
      return res;
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.addingSkills",
        })
      );
    }
  };

export const deleteSkill =
  ({ skillId, userId }) =>
  async (dispatch) => {
    try {
      const { status } = await axios.delete(`${API}/api/skills/${skillId}`);
      if (status === 204) {
        dispatch(retrieveProfile(userId));
        dispatch(retrieveUserSkills(userId));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.addingSkills",
        })
      );
    }
  };

export const deleteSkillRequirement =
  ({ skillId }) =>
  async (dispatch) => {
    try {
      return await axios.delete(
        `${API}/api/employer/skill-requirements/${skillId}`
      );
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.addingSkills",
        })
      );
    }
    return {};
  };

export const updateSettings = (settings) => async (dispatch) => {
  try {
    const { status, data } = await axios.patch(
      `${API}/api/my-settings/${settings.user}`,
      {
        ...settings,
      }
    );
    if (status === 200) {
      dispatch(populateSettings(data));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.addingSkills",
      })
    );
  }
};

export const updateNotificationPreferences =
  ({ notificationUuid, subscribe }) =>
  async (dispatch) => {
    try {
      const { status, data } = await axios.patch(
        `${API}/api/notifications/unsubscribe/${notificationUuid}`,
        {
          subscribe,
        }
      );
      if (status === 200) {
        dispatch(populateSettings(data));
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.updateNotificationPreferences",
        })
      );
    }
  };

export const retrieveSettings = () => async (dispatch, getState) => {
  try {
    const token = localStorage.getItem("jwt");
    if (!token) {
      return null;
    }

    const decodedJWT = jwt_decode(token);
    const { data, status } = await axios.get(
      `${API}/api/my-settings/${decodedJWT.user_id}`
    );
    if (status === 200) {
      dispatch(populateSettings(data));
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.retrievingSettings",
      })
    );
  }
};

export const deleteAccount =
  (user, silent = false) =>
  async (dispatch) => {
    try {
      const { status } = await axios.delete(`${API}/api/user/${user}/`);
      if (status === 204 && !silent) {
        localStorage.removeItem("jwt");
        window.location = "/logout?referrer=delete";
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "profile.deleteProfile",
        })
      );
    }
  };

export const deleteOrganization = (organization) => async (dispatch) => {
  try {
    const { status } = await axios.delete(
      `${API}/api/employers/${organization}/`
    );
    if (status === 204) {
      localStorage.removeItem("jwt");
      window.location = "/?referrer=delete";
    }
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "profile.deleteOrganization",
      })
    );
  }
};

export const personifyEmployer = async (employerId) => {
  try {
    const {
      data: { token },
    } = await axios.post(`${API}/personify-employee/`, {
      employerId,
    });
    localStorage.setItem("jwt", token);
    setTimeout(() => {
      window.location = "/dashboard";
    }, 0);
  } catch (e) {
    console.warn(e);
  }
};

export const revertPersonification = async () => {
  try {
    const {
      data: { token },
    } = await axios.put(`${API}/personify-employee/`);
    localStorage.setItem("jwt", token);
    setTimeout(() => {
      window.location = "/dashboard";
    }, 0);
  } catch (e) {
    console.warn(e);
  }
};

export const createNewReferral =
  ({ user, firstName, lastName, email, accountType, organization }) =>
  async (dispatch) => {
    try {
      await axios.post(`${API}/api/referrals/`, {
        user,
        firstName,
        lastName,
        email,
        accountType,
        organization,
      });
    } catch (e) {
      console.warn(e);
    }
  };
