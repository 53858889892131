import React, { Component } from 'react';
import compose from 'HOC/compose';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as profileActions from 'actions/profile';
import * as authActions from 'actions/auth';

const withProfile = WrappedComponent => {
  class HOC extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return compose(
    connect(
      ({ user }) => ({ ...user }),
      dispatch =>
        bindActionCreators({ ...profileActions, ...authActions }, dispatch),
    ),
  )(HOC);
};

export default withProfile;
