import React, { useEffect, lazy, Suspense } from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";

import withProfile from "HOC/withProfile";
import initGoogleAnalytics from "utils/GoogleAnalytics";

import WithTracker from "hooks/WithTracker";
import LoadingOverlay from "components/LoadingOverlay";
import SuspenseLoader from "containers/SuspenseLoader";
import LandingPage from "containers/LandingPage";
import CompleteSignUp from "containers/CompleteSignUp";
import MinimalAppbar from "containers/MinimalAppbar";
import Protected from "containers/Protected";

const ClaimTypeformAccount = lazy(() =>
  import("containers/ClaimTypeformAccount")
);
const EmployerHome = lazy(() => import("pages/EmployerHome"));
const ConfirmEmail = lazy(() => import("containers/ConfirmEmail"));
const StaffLanding = lazy(() => import("pages/StaffLanding"));
const Login = lazy(() => import("../containers/LoginContainer"));
const ModalContainer = lazy(() => import("../containers/ModalContainer"));
const ProfileCompletion = lazy(() => import("../containers/ProfileCompletion"));
const ResetPassword = lazy(() => import("../containers/ResetPassword"));
const Signup = lazy(() => import("../containers/Signup"));
const Logout = lazy(() => import("../containers/Logout"));
const Settings = lazy(() => import("containers/Settings"));
const RedirectWithParams = lazy(() => import("../containers/Redirect"));
const EmployerProfile = lazy(() => import("pages/EmployerProfile"));
const EmployerOverview = lazy(() => import("pages/EmployerOverview"));
const EmployeeBasicInfo = lazy(() => import("pages/EmployeeBasicInfo"));
const JobSeekerDashboard = lazy(() => import("pages/JobSeekerDashboard"));
const StaffMatchingDashboard = lazy(() =>
  import("pages/StaffMatchingDashboard")
);
const RoleFilterSetConfiguration = lazy(() =>
  import("pages/RoleFilterSetConfiguration")
);
const MatchesListView = lazy(() => import("pages/MatchesListView"));
const EmployerMatchingDashboard = lazy(() =>
  import("pages/EmployerMatchingDashboard")
);
const Unsubscribe = lazy(() => import("pages/Unsubscribe"));
const ForwardEmployerContract = lazy(() =>
  import("containers/ForwardEmployerContract")
);
const Deploying = lazy(() => import("../components/Deploying"));

/**
 * If deploying, uncomment both lines:
 * const Deploying = lazy(() => import('../components/Deploying'));
 * <Route component={Deploying} />
 */
const App = ({ profile, expiresAt, token }) => {
  const { user, jobTitle } = profile || {};
  const { userType } = token || {};

  useEffect(() => initGoogleAnalytics(user), []);

  const isLoggedIn = expiresAt && expiresAt !== "expired";
  const interceptEmployeeDetails = isLoggedIn && user && !jobTitle;
  const isDeploying = false;

  if (isDeploying) {
    return <Deploying />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseLoader />}>
        <WithTracker />
        <ModalContainer />
        <Routes>
          <Route index path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/employer/signup"
            element={<Signup isEmployer={true} />}
          />
          <Route
            path="/jobseeker/signup"
            element={<Signup isJobSeeker={true} />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/claim-your-account"
            element={<ClaimTypeformAccount />}
          />
          <Route path="/redirect" element={<RedirectWithParams />} />
          <Route path="/confirm_email" element={<ConfirmEmail />} />
          <Route path="/loading" element={<LoadingOverlay />} />
          <Route path="/complete-sign-up" element={<CompleteSignUp />} />
          <Route
            path="/forward-employer-contract"
            element={<ForwardEmployerContract />}
          />
          <Route
            path="/employers/:employerName"
            element={<EmployerOverview />}
          />
          <Route element={<MinimalAppbar />}>
            <Route
              path="/notifications/unsubscribe/:notificationUuid"
              element={<Unsubscribe />}
            />
          </Route>

          {/* Employee Routes */}
          {userType === "employee" ? (
            <Route element={<Protected allowedUserTypes={["employee"]} />}>
              <Route element={<MinimalAppbar />}>
                <Route
                  path={interceptEmployeeDetails ? "" : "/employee/details"}
                  title="My Profile"
                  element={<EmployeeBasicInfo />}
                />

                <Route path="/role">
                  <Route index element={<EmployerProfile />} />
                  <Route path=":roleUuid">
                    <Route index element={<EmployerProfile />} />
                    <Route path=":questionName" element={<EmployerProfile />} />
                  </Route>
                </Route>

                <Route
                  path="/settings"
                  title="Settings"
                  element={<Settings />}
                />
                <Route
                  path="/profile/:questionName"
                  title="Company Profile"
                  element={<EmployerProfile />}
                />
                <Route
                  index
                  path="/dashboard"
                  title="Dashboard"
                  element={<EmployerHome />}
                />
                <Route
                  path="/employer/:employerName"
                  element={<EmployerOverview />}
                />

                <Route path="/matching">
                  <Route index element={<EmployerMatchingDashboard />} />
                  <Route
                    path=":activeRole"
                    element={<EmployerMatchingDashboard />}
                  />
                </Route>

                <Route path="/" element={<EmployerHome />} />
              </Route>
            </Route>
          ) : null}

          {/* JobSeeker Routes */}
          {userType === "jobseeker" ? (
            <Route element={<Protected allowedUserTypes={["jobseeker"]} />}>
              <Route element={<MinimalAppbar />}>
                <Route index element={<LandingPage />} />
                <Route
                  path="/settings"
                  title="Settings"
                  element={<Settings />}
                />

                <Route path="/profile">
                  <Route index element={<JobSeekerDashboard />} />
                  <Route
                    path="/profile_overview"
                    element={<ProfileCompletion />}
                  />
                  <Route path=":questionName" element={<ProfileCompletion />} />
                </Route>
                <Route
                  path="/dashboard"
                  title="Dashboard"
                  element={<ProfileCompletion />}
                />
                <Route
                  title="Matches"
                  path="/matching"
                  element={<JobSeekerDashboard />}
                />
                <Route
                  title="Matches"
                  path="/v2/dashboard"
                  element={<JobSeekerDashboard />}
                />

                <Route path="/profile" render={() => <Navigate to="/" />} />

                <Route exact path="*" element={<LandingPage />} />
              </Route>
            </Route>
          ) : null}

          {/* Staff Routes */}
          {userType === "staff" ? (
            <Route element={<MinimalAppbar />}>
              <Route index element={<LandingPage />} />
              <Route path="/settings" title="Settings" element={<Settings />} />
              <Route path="/dashboard">
                <Route index element={<StaffLanding />} />
                <Route path=":listName" element={<StaffLanding />} />
              </Route>
              <Route
                path="/v2/dashboard"
                title="Matching"
                element={<StaffMatchingDashboard />}
              />
              <Route
                path="/matching"
                title="Matching"
                element={<StaffMatchingDashboard />}
              />
              <Route
                path="/role/filterset/:roleUuid"
                title="Role Queryset"
                element={<RoleFilterSetConfiguration />}
              />

              <Route path="/role/matches">
                <Route
                  index
                  element={<MatchesListView showAllJobSeekers={true} />}
                />
                <Route path=":roleUuid" element={<MatchesListView />} />
              </Route>
            </Route>
          ) : null}

          <Route
            path="*"
            element={<Navigate to={userType ? "/dashboard" : "/"} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default withProfile(App);
