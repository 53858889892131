import * as R from "ramda";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTime } from "luxon";

// eslint-disable-next-line react-hooks/rules-of-hooks
export const dotPath = R.useWith(R.path, [R.split(".")]);

export const debounce = R.curry((immediate, timeMs, fn) => () => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;

      if (!immediate) {
        R.apply(fn, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, timeMs);

    if (callNow) {
      R.apply(fn, args);
    }

    return timeout;
  };
});

export const capitalize = (txt = "") =>
  txt && txt.charAt(0).toUpperCase() + txt.slice(1);

/**
 * @param {string} str string you want to convert to snake case
 */
export const toSnakeCase = (str) =>
  str?.replace(/([A-Z])/g, (x) => R.concat("_", x.toLowerCase()));

export const removeEmptyParams = (p) =>
  Object.keys(p).reduce(
    (s, k) => (p[k] || p[k] === false ? { ...s, [toSnakeCase(k)]: p[k] } : s),
    {}
  );

export const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return isMobile;
};

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) {
    return null;
  }

  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const isBoolean = (val) => val === true || val === false;

export const formatFilterByListLabel = (filters) => {
  if (!filters || !Array.isArray(filters)) {
    return [];
  }

  return filters?.map(({ label }) => label).join(";");
};

export const formatFilterList = (filters) =>
  filters?.map(({ value }) => value).join(";");

export const getDirtyFilterCount = (initialFilters, newFilters) => {
  return Object.keys(initialFilters).filter((k) => {
    if (
      k !== "lastUpdated" &&
      k !== "filterGroupId" &&
      !R.equals(initialFilters[k], newFilters[k])
    ) {
      return true;
    }
    return false;
  })?.length;
};

export const uniqBy = (li, prp) => R.uniqBy(R.prop(prp), li);

const units = ["year", "month", "week", "day", "hour", "minute", "second"];
export const timeAgo = (date) => {
  if (!date) return null;
  let dateTime = DateTime.fromISO(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};
