import React, { useEffect, useState } from "react";
import { useUser, simpleFetch, useIsCompanyProfileComplete } from "hooks";
import { useTheme } from "@mui/material/styles";
import { useNavigate, Outlet, useLocation, Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import cn from "classnames";

import { revertPersonification } from "actions/profile";

import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Dashboard from "@mui/icons-material/Dashboard";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Settings from "@mui/icons-material/Settings";
import GroupAddRounded from "@mui/icons-material/GroupAddRounded";
import LocalHospital from "@mui/icons-material/LocalHospital";
import Dangerous from "@mui/icons-material/CancelPresentation";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import ProgressiveImage from "components/Image";
import RoleProgressPopover from "containers/RoleProgressPopover";
import Appbar from "components/AppBar";

import s from "./MinimalAppbar.module.css";

export default function MinimalAppbar({ children }) {
  const history = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [matchCount, setMatchCount] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [canAccessMatchesPage, setCanAccessMatchesPage] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isNearlyMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { isLoggedIn, isJobSeeker, isEmployee, isStaff, profile } = useUser();
  const isPersonifyingEmployer = isEmployee && profile?.isStaff;

  useEffect(() => {
    const init = async () => {
      if (isEmployee || isJobSeeker) {
        const fetchMatchCount = async () => {
          const matchCountUrl = `/api/employer/new-match-count/`;
          const {
            data: { count, canAccessMatchingPage },
          } = await simpleFetch(matchCountUrl);
          setMatchCount(count);
          setCanAccessMatchesPage(canAccessMatchingPage);
        };
        fetchMatchCount();
      }
    };
    init();
  }, [isJobSeeker, isEmployee]);

  const linkTo = (to) => {
    history(to);
    setMobileDrawerOpen(false);
  };
  const [isCompanyProfileComplete] = useIsCompanyProfileComplete();
  const canViewDashboard = () => {
    if (isJobSeeker) {
      return profile.completedSections.includes("core");
    } else if (isEmployee) {
      return isCompanyProfileComplete;
    }
    return true;
  };
  const canAccessDashboard = !!canViewDashboard();

  const renderLoginButton = () => (
    <div className={s.loginContainer}>
      <Button component={Link} to="/login" className={s.loginButton}>
        Login
      </Button>
    </div>
  );

  const renderEmployeeBadge = () => {
    if (!isEmployee || isNearlyMobile) {
      return <div />;
    }

    if (pathname?.includes("/role/")) {
      return <RoleProgressPopover />;
    }

    return (
      <div className={s.employerBadge}>
        <ProgressiveImage
          className={s.employerLogo}
          src={
            profile?.logo ||
            "https://workmonger-logos.s3.amazonaws.com/company-placeholder.png"
          }
          alt="employer logo"
        />
        <div className={s.employerName}>{profile?.name}</div>
      </div>
    );
  };

  const renderLoggedInRightSection = () => {
    if (isMobile) {
      return (
        <>
          <div className={s.appbarContent}>
            <div />
            <div className={s.rightAppbar}>
              <MenuIcon
                className={s.hamburgerMenu}
                onClick={() => setMobileDrawerOpen(true)}
              />
            </div>
          </div>
          <Drawer
            anchor="bottom"
            open={mobileDrawerOpen}
            onClose={() => setMobileDrawerOpen(false)}
            className={s.drawer}
            classes={{
              paper: s.drawer,
              paperAnchorBottom: s.drawer,
            }}
          >
            {canAccessDashboard && (
              <div
                onClick={() => linkTo("/dashboard")}
                className={s.mobileLink}
              >
                <Dashboard />
                <div>Dashboard</div>
              </div>
            )}
            {canAccessDashboard && (
              <div onClick={() => linkTo("/matching")} className={s.mobileLink}>
                <Badge
                  badgeContent={matchCount ? `${matchCount} New` : null}
                  color="error"
                  classes={{
                    badge: s.badgeNew,
                  }}
                >
                  <GroupAddRounded />
                </Badge>
                <div>Matches</div>
              </div>
            )}
            {isPersonifyingEmployer ? (
              <div>
                <div
                  role="button"
                  onClick={() => revertPersonification()}
                  className={s.mobileLink}
                >
                  <Dangerous />
                  <div>Go Back to Staff Account</div>
                </div>
              </div>
            ) : null}
            <div onClick={() => linkTo("/settings")} className={s.mobileLink}>
              <Settings />
              <div>Settings</div>
            </div>
            {!isStaff && (
              <div onClick={window.FreshWidget.show} className={s.mobileLink}>
                <LocalHospital />
                <div>Support</div>
              </div>
            )}
            <div onClick={() => linkTo("/logout")} className={s.mobileLink}>
              <ExitToApp />
              <div>Log Out</div>
            </div>
          </Drawer>
        </>
      );
    }

    const showMatchingLink =
      isStaff || (canAccessDashboard && canAccessMatchesPage);

    return (
      <div className={s.appbarContent}>
        {renderEmployeeBadge()}
        <div className={s.rightAppbar}>
          {canAccessDashboard && (
            <div
              onClick={() => linkTo("/dashboard")}
              className={cn(s.menuItem, {
                [s.active]:
                  pathname.includes("dashboard") && !pathname.includes("v2"),
              })}
            >
              <Dashboard />
              <div>Dashboard</div>
            </div>
          )}
          {showMatchingLink && (
            <div
              onClick={() => linkTo("/matching")}
              className={cn(s.menuItem, {
                [s.active]: pathname.includes("matching"),
              })}
            >
              <Badge
                badgeContent={matchCount ? `${matchCount} New` : null}
                color="error"
                classes={{
                  badge: s.badgeNew,
                }}
              >
                <GroupAddRounded />
              </Badge>
              <div>Matches</div>
            </div>
          )}
          <div
            onClick={() => linkTo("/settings")}
            className={cn(s.menuItem, {
              [s.active]: pathname.includes("settings"),
            })}
          >
            <Settings />
            <div>Settings</div>
          </div>
          {!isStaff ? (
            <div onClick={window.FreshWidget.show} className={s.menuItem}>
              <LocalHospital />
              <div>Support</div>
            </div>
          ) : null}
          <div onClick={() => linkTo("/logout")} className={s.menuItem}>
            <ExitToApp />
            <div>Log Out</div>
          </div>
          {isPersonifyingEmployer ? (
            <div>
              <div
                role="button"
                onClick={() => revertPersonification()}
                className={cn(s.menuItem, s.exitStaffAccount)}
              >
                <div>Back to Staff Account</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <>
      <Appbar
        right={() =>
          isLoggedIn ? renderLoggedInRightSection() : renderLoginButton()
        }
      />
      <div className={s.content}>
        <Outlet />
      </div>
    </>
  );
}
