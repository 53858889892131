export default (
  state = {
    modals: [],
  },
  action,
) => {
  switch (action.type) {
    case 'MODAL/OPEN':
      return {
        ...state,
        modals: [...state.modals, action.modal],
      };
    case 'MODAL/CLOSE':
      return {
        ...state,
        modals: state.modals.filter(
          ({ modalId }) => modalId !== action.modalId,
        ),
      };
    default:
      return state;
  }
};
