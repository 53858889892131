import React, { Component } from 'react';

import ProgressiveImage from 'react-progressive-image';

import defaultImage from './placeholder.png';

class Image extends Component {
  render() {
    const { placeholder, src, alt, ...restProps } = this.props;
    return (
      <ProgressiveImage src={src} placeholder={placeholder}>
        {(src, loading) => (
          <img
            src={loading ? placeholder || defaultImage : src}
            alt={alt}
            {...restProps}
          />
        )}
      </ProgressiveImage>
    );
  }
}

export default Image;
