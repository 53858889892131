/**
 * This is a wrapper around a route
 * that determines if a user is logged in.
 * This can be expanded to include
 * multiple user types checking.
 */

import React from "react";
import { useUser } from "hooks";

import { DateTime } from "luxon";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

const Protected = ({ allowedUserTypes = [] }) => {
  const { token } = useUser();
  const exp = token?.exp || 0;
  const isLoggedIn = DateTime.local() < DateTime.fromSeconds(exp);

  return isLoggedIn && allowedUserTypes.includes(token.userType) ? (
    <Outlet />
  ) : (
    <Navigate replace to="/login?referrer=requires_login" />
  );
};

export default Protected;
