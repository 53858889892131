export const openModal = ({
  modalId,
  variant,
  canClose = true,
  ...restProps
}) => ({
  type: 'MODAL/OPEN',
  modal: {
    modalId,
    variant,
    canClose: canClose,
    ...restProps,
  },
});

export const closeModal = modalId => ({
  type: 'MODAL/CLOSE',
  modalId,
});
