import humps from 'humps';

export default function(client) {
  client.interceptors.request.use(request => {
    const payloadType = request.data && request.data.constructor.name;
    if (request.data && (payloadType === 'Object' || payloadType === 'Array')) {
      request.rawData = request.data;
      request.data = humps.decamelizeKeys(request.data);
    }
    if (request.params) {
      request.rawParams = request.params;
      request.params = humps.decamelizeKeys(request.params);
    }
    return request;
  });

  client.interceptors.response.use(response => {
    if (response.data) {
      response.rawData = response.data;
      response.data = humps.camelizeKeys(response.data);
    }
    return response;
  });

  return client;
}
