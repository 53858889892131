import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import s from "./LoadingOverlay.module.css";

export default function LoadingOverlay({ hideCircularProgress }) {
  return (
    <div className={s.overlay}>
      {hideCircularProgress ? null : (
        <CircularProgress disableShrink className={s.progress} />
      )}
    </div>
  );
}
