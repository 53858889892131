import React from "react";
import { useActions, useUser, get, useAsync } from "hooks";

import * as profileActions from "../../actions/profile";
import s from "./TermsOfServiceUpdatedBanner.module.css";

const TermsOfServiceUpdatedBanner = (props) => {
  const {
    isLoggedIn,
    profile: { id: userId, viewedTermVersion },
  } = useUser();
  const { updateBaseUserFields } = useActions({ ...profileActions });

  const { value: { latestVersion } = {} } = useAsync(
    get(`/api/latest-terms/1/`),
    {
      http: true,
      immediate: false,
      executeOnCondition: isLoggedIn,
      initialValue: {},
    }
  );

  const shouldShowBanner =
    isLoggedIn &&
    viewedTermVersion &&
    latestVersion &&
    viewedTermVersion !== latestVersion;

  const updateLastViewedTerms = () => {
    updateBaseUserFields({
      userId,
      updates: {
        viewedTermVersion: latestVersion,
      },
      doUpdateProfile: true,
    });
  };

  if (!shouldShowBanner) return null;

  return (
    <div className={s.banner}>
      <div>
        We have updated our terms of service.
        <a
          className={s.link}
          href="https://www.iubenda.com/terms-and-conditions/88723568"
          rel="noopener noreferrer"
          target="_blank"
        >
          View updated Terms
        </a>
      </div>
      <div className={s.closeLabel} onClick={updateLastViewedTerms}>
        Confirm and Dismiss
      </div>
    </div>
  );
};

export default TermsOfServiceUpdatedBanner;
