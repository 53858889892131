import { combineReducers, createStore } from 'redux';

import reducers from 'reducers';
import sagas from 'sagas';

import devTools from './devTools';
import sagaMiddleware from './saga';

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  devTools(),
);

sagaMiddleware.run(sagas);

export default store;
