import axios, { createClient } from "utils/axios";
import jwt_decode from "jwt-decode";
import humps from "humps";

import { API } from "config";
import { addError } from "./errorHandling";
import * as profileActions from "./profile";

export const setUserToken = (token) => ({
  type: "AUTH/SET_USER_TOKEN",
  token: humps.camelizeKeys(jwt_decode(token)),
});

export const setToken = (token) => ({
  type: "AUTH/SET_TOKEN",
  token: humps.camelizeKeys(jwt_decode(token)),
});

export const populateProfile = (info) => ({
  type: "AUTH/POPULATE_BASIC_INFO",
  info,
});

export const addFailedLoginAttempt = () => ({
  type: "AUTH/ADD_FAILED_LOGIN_ATTEMPT",
});

export const retrieveNewToken = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("jwt");

    if (!token) {
      window.location.href = "/login";
    }

    const {
      status,
      data: { token: newToken },
    } = await axios.post(
      `${API}/refresh/`,
      {
        token,
      },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    if (status === 200) {
      localStorage.removeItem("jwt");
      localStorage.setItem("jwt", newToken);
      dispatch(setUserToken(newToken));
    }
  } catch (e) {
    window.location.href = "/login";
  }
};

export const login = (credentials, redirectOnSuccess) => async (dispatch) => {
  let error;
  let res;
  try {
    res = await axios.post(`${API}/login/`, credentials);
    if (res.status === 200) {
      localStorage.setItem("jwt", res.data.token);
      dispatch(setUserToken(res.data.token));
      window.location = redirectOnSuccess || "/?referrer=login";
      return { successfullyAuthenticated: true };
    }
  } catch (e) {
    // Custom Error Responses
    if (e?.response?.data?.toString().includes("Locked")) {
      error = e.response.data;
    }
    dispatch(addFailedLoginAttempt(true));
    dispatch(
      addError({
        message: e.message,
        module: "auth",
      })
    );
  }
  if (res?.response?.data?.toString().includes("Locked")) {
    error = res.response.data;
  }
  return { successfullyAuthenticated: false, error };
};

export const requestPasswordReset = (email) => () =>
  axios.post(`${API}/reset-password/`, {
    email,
  });

export const resetPassword =
  ({ password, token }, enqueueSnackbar) =>
  async (dispatch) => {
    try {
      const { status, ...rest } = await axios.post(
        `${API}/reset-password/confirm/`,
        {
          password,
          token,
        }
      );
      if (status === 200) {
        localStorage.setItem("jwt", rest.data?.token);
        dispatch(setUserToken(rest.data?.token));
        window.location.href = "/";
      } else {
        enqueueSnackbar("There was an issue resetting your password", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } catch (e) {
      dispatch(
        addError({
          message: e.message,
          module: "auth.resetPassword",
        })
      );
    }
  };

export const validateToken = (token) => async (dispatch) => {
  try {
    const { status } = await axios.post(
      `${API}/reset-password/validate_token/`,
      {
        token,
      }
    );
    return status;
  } catch (e) {
    dispatch(
      addError({
        message: e.message,
        module: "auth.validateResetPasswordToken",
      })
    );
  }
  return 400;
};

export const retrieveProfile = async () => {
  const token = localStorage.getItem("jwt");
  const client = createClient();
  return await client.get(`${API}/api/my-profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const confirmEmail = (userId) => () =>
  axios.patch(`${API}/api/user/${userId}/confirm_email/`);

export const resendConfirmationEmail = (userId) => () =>
  axios.get(`${API}/resend-confirmation-email/`);

export const createUser = (newUser) => async (dispatch) => {
  try {
    const { response, status, data } = await axios.post(
      `${API}/api/user`,
      newUser
    );

    if (status === 200 || status === 201) {
      localStorage.setItem("jwt", data.token);
      await dispatch(setUserToken(data.token));
      await dispatch(profileActions.retrieveProfile());

      return {
        ...data,
        success: true,
      };
    } else {
      return {
        success: false,
        errorMessage: Object.values(response?.data || {})[0],
      };
    }
  } catch (e) {
    console.warn(e);
    let errorMessage = Object.values(e.response?.data || {})?.[0];
    dispatch(
      addError({
        message: e.message,
        module: "auth.signup",
      })
    );
    return { success: false, errorMessage };
  }
};

export const createStaffUser = (newUser) => async (dispatch) => {
  try {
    const { response, status, data } = await axios.post(
      `${API}/api/user/create_coworker/`,
      newUser
    );

    if (status === 201) {
      return {
        ...data,
        success: true,
      };
    } else {
      return {
        success: false,
        errorMessage: Object.values(response?.data || {})[0],
      };
    }
  } catch (e) {
    const errorMessage = Object.values(e.response?.data || {})?.[0];
    dispatch(
      addError({
        message: e.message,
        module: "auth.signup",
      })
    );
    return { success: false, errorMessage };
  }
};

export const updateEmployeePermissions = (employee) => async (dispatch) => {
  try {
    const { response, status, data } = await axios.patch(
      `${API}/api/employer/staff/${employee?.id}`,
      employee
    );

    if (status === 200) {
      return {
        ...data,
        success: true,
      };
    } else {
      return {
        success: false,
        errorMessage: Object.values(response?.data || {})[0],
      };
    }
  } catch (e) {
    const errorMessage = Object.values(e.response?.data || {})?.[0];
    dispatch(
      addError({
        message: e.message,
        module: "auth.signup",
      })
    );
    return { success: false, errorMessage };
  }
};
