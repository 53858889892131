import React, { useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "containers/WithRouter";

import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRight from "@mui/icons-material/ChevronRight";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Business from "@mui/icons-material/Business";
import BusinessCenter from "@mui/icons-material/BusinessCenter";
import Info from "@mui/icons-material/Info";
import Web from "@mui/icons-material/Web";
import GroupAdd from "@mui/icons-material/GroupAdd";
import ProgressiveImage from "components/Image";

import s from "./ExternalLinks.module.css";

const ExternalLinks = ({ history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isVerticallyConstrained = useMediaQuery("(max-height:710px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isMobile || isVerticallyConstrained) {
    return (
      <>
        <MenuIcon className={s.drawerIcon} onClick={handleClick} />
        <SwipeableDrawer
          anchor="bottom"
          open={!!anchorEl}
          onOpen={() => null}
          onClose={handleClose}
        >
          <div className={s.drawerContent}>
            <CloseIcon onClick={handleClose} className={s.closeIcon} />
          </div>
          <div className={s.menuList}>
            <Link className={s.mobileLogin} to="/login">
              Login
            </Link>
            <Link className={s.mobileLogin} to="/signup">
              Signup
            </Link>
            <Divider />
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/jobseekers/"
            >
              <AssignmentInd className={s.linkIcon} /> JobSeekers{" "}
              <ChevronRight className={s.caret} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/employers/"
            >
              <Business className={s.linkIcon} /> Employers{" "}
              <ChevronRight className={s.caret} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/career-services/"
            >
              <BusinessCenter className={s.linkIcon} /> Career Services{" "}
              <ChevronRight className={s.caret} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/about-us/"
            >
              <Info className={s.linkIcon} /> About Us{" "}
              <ChevronRight className={s.caret} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/blog/"
            >
              <Web className={s.linkIcon} /> Blog{" "}
              <ChevronRight className={s.caret} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={s.mobileLink}
              href="https://worktogethertalent/referral/"
            >
              <GroupAdd className={s.linkIcon} /> Invite{" "}
              <ChevronRight className={s.caret} />
            </a>
          </div>
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <div className={s.container}>
      <ProgressiveImage
        className={s.logo}
        alt="workmonger-logo"
        src="https://s3.amazonaws.com/workmonger-logos/WorkTogether_Logo-Primary.png"
      />

      <div className={s.plainLinks}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
          href="https://worktogethertalent.com"
        >
          Home
        </a>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
          href="https://worktogethertalent.com/employers/"
        >
          Employers
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
          href="https://worktogethertalent.com/career-services/"
        >
          Career Services
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
          href="https://worktogethertalent.com/"
        >
          About Us
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={s.link}
          href="https://worktogethertalent.com/referral/"
        >
          Invite
        </a> */}
        <span onMouseLeave={handleClose}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onMouseEnter={handleClick}
            className={s.link}
          >
            Signup / Login
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            classes={{ list: s.menu }}
          >
            <MenuItem
              className={s.menuItem}
              onClick={() => {
                history("/login");
                handleClose();
              }}
            >
              Login
            </MenuItem>
            <MenuItem
              className={s.menuItem}
              onClick={() => {
                history("/signup");
                handleClose();
              }}
            >
              Signup
            </MenuItem>
          </Menu>
        </span>
      </div>
    </div>
  );
};

export default withRouter(ExternalLinks);
