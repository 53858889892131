import * as R from "ramda";
import axios from "utils/axios";
import { API } from "config";
import { DateTime } from "luxon";
import humps from "humps";
import { useNavigate } from "react-router-dom";

import { useState, useMemo, useCallback, useRef, useEffect } from "react";

import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";

import organizationQuestions from "pages/EmployerProfile/organizationQuestions";

export function useUser() {
  const userState = useSelector((state) => {
    const userType = state.user?.profile?.userType;
    const token = state.user?.token;
    const timeOfExp = token?.exp || 0;
    const isLoggedIn = DateTime.local() < DateTime.fromSeconds(timeOfExp);

    return {
      isLoggedIn,
      isJobSeeker: userType === "jobseeker",
      isEmployee: userType === "employee",
      isStaff: userType === "staff",
      ...state.user,
    };
  });
  return userState;
}

export const getOrgQuestions = () => {
  const questions = Object.values(organizationQuestions);
  const lastCompanyQuestionIndex = questions.findIndex(
    ({ key }) => key === "referralType"
  );

  const orgQuestions = questions.slice(0, lastCompanyQuestionIndex + 1);
  return orgQuestions;
};

export function useIsCompanyProfileComplete() {
  const orgQuestions = getOrgQuestions();
  return useSelector((state) => {
    const profile = state.user?.profile;

    const validAnswers = orgQuestions.filter(({ isValid }) => {
      if (!isValid) {
        return true;
      }
      return isValid(profile);
    });

    return [
      validAnswers.length === orgQuestions.length,
      Math.round((validAnswers.length / orgQuestions.length) * 100),
    ];
  });
}

export function useGetRoleCompletionBySection() {
  const { role } = useUser();

  const questions = Object.values(organizationQuestions);
  const invalidAnswers = [];
  const sections = [
    {
      firstKey: "introToRoleSection",
      lastKey: "jobFileUpload",
      sectionName: "Role Basics",
    },
    {
      firstKey: "undergradDegreeRequirement",
      lastKey: "preferredColleges",
      sectionName: "Education",
    },
    {
      firstKey: "yearsFullTimeExperience",
      lastKey: "functionalSkills3",
      sectionName: "Work Experience",
    },
    {
      firstKey: "languages",
      lastKey: "skills",
      sectionName: "Skills",
    },
  ];

  const completionBySection = sections.reduce(
    (completion, { firstKey, lastKey, sectionName }, index) => {
      const firstIndex = questions.findIndex(({ key }) => key === firstKey);
      const lastIndex = questions.findIndex(({ key }) => key === lastKey);
      const questionSlice = questions.slice(firstIndex, lastIndex + 1);

      const validAnswers = questionSlice.filter(
        ({ key, options, variant, isValid }) => {
          if (!isValid) {
            return true;
          }
          // radio options can return false which
          // would make isValid useless
          // This escape hatch makes it easier.
          const isBooleanRadio = variant === "radio" && options?.length === 2;
          if (isBooleanRadio && role[key] != null) {
            return true;
          }

          const v = isValid(role);
          if (!v) {
            invalidAnswers.push(key);
          }
          return v;
        }
      );
      const priorSectionComplete =
        !index ||
        !!completion[
          Object.values(sections).map(({ sectionName }) => sectionName)[
            index - 1
          ]
        ];
      return {
        ...completion,
        [sectionName]: !!(
          priorSectionComplete && validAnswers.length === questionSlice.length
        ),
      };
    },
    {}
  );
  return [completionBySection, invalidAnswers];
}

export function getRoleProgress(role) {
  const questions = Object.values(organizationQuestions);
  const invalidAnswers = [];
  const sections = [
    {
      firstKey: "introToRoleSection",
      lastKey: "jobFileUpload",
      sectionName: "Role Basics",
    },
    {
      firstKey: "undergradDegreeRequirement",
      lastKey: "preferredColleges",
      sectionName: "Education",
    },
    {
      firstKey: "yearsFullTimeExperience",
      lastKey: "functionalSkills3",
      sectionName: "Work Experience",
    },
    {
      firstKey: "languages",
      lastKey: "skills",
      sectionName: "Skills",
    },
  ];

  const completionBySection = sections.reduce(
    (completion, { firstKey, lastKey, sectionName }, index) => {
      const firstIndex = questions.findIndex(({ key }) => key === firstKey);
      const lastIndex = questions.findIndex(({ key }) => key === lastKey);
      const questionSlice = questions.slice(firstIndex, lastIndex + 1);

      const validAnswers = questionSlice.filter(
        ({ key, options, variant, isValid }) => {
          if (!isValid) {
            return true;
          }
          // radio options can return false which
          // would make isValid useless
          // This escape hatch makes it easier.
          const isBooleanRadio = variant === "radio" && options?.length === 2;
          if (isBooleanRadio && role[key] != null) {
            return true;
          }

          const v = isValid(role);
          if (!v) {
            invalidAnswers.push(key);
          }
          return v;
        }
      );
      const priorSectionComplete =
        !index ||
        !!completion[
          Object.values(sections).map(({ sectionName }) => sectionName)[
            index - 1
          ]
        ];
      return {
        ...completion,
        [sectionName]: !!(
          priorSectionComplete && validAnswers.length === questionSlice.length
        ),
      };
    },
    {}
  );
  return [completionBySection, invalidAnswers];
}

export function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : deps // eslint-disable-line
  );
}

export const simpleFetch = (endpoint) => axios.get(`${API}${endpoint}`);

export const get =
  (endpoint, ...rest) =>
  () =>
    axios.get(`${API}${endpoint}`);

export function useAsync(
  asyncFunction,
  {
    http = false,
    checkStatus,
    doResetValues = true,
    immediate = true,
    initialValue = null,
    initialPendingValue = true,
    executeOnCondition = true,
    executeOnChange = [],
    debug = false,
  }
) {
  const [pending, setPending] = useState(initialPendingValue);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);

  const execute = useCallback(() => {
    setPending(true);
    if (doResetValues) {
      setValue(initialValue);
    }
    setError(null);
    return asyncFunction()
      .then((res) => {
        if (http) {
          if ((checkStatus && checkStatus === res?.status) || !checkStatus) {
            setValue(res?.data);
          } else {
            setError(true);
          }
        } else {
          return setValue(res);
        }
      })
      .catch((error) => setError(error))
      .finally(() => {
        setPending(false);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (debug) {
      console.info({
        description: "**This is calling when...",
        immediate,
        executeOnChange,
        executeOnCondition,
      });
    }
    if ((immediate || executeOnChange?.length) && executeOnCondition) {
      execute();
    }
  }, [immediate, executeOnCondition, ...executeOnChange]);

  return { execute, pending, value, error };
}

export function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // eslint-disable-line

  return keyPressed;
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useFiltersWithFetch({
  initialState = {},
  url = "",
  updated,
  staticFilters = {},
  executeOnCondition = true,
}) {
  const [filters, setFilters] = useState(initialState);
  const combinedFilters = {
    ...filters,
    ...staticFilters,
  };
  const serializedFilters = Object.entries(combinedFilters)
    .filter(([key, val]) => val != null)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

  const request = `${url}?${serializedFilters}`;

  const asyncResponse = useAsync(get(request), {
    http: true,
    checkStatus: 200,
    immediate: false,
    doResetValues: true,
    executeOnChange: [request],
    executeOnCondition,
  });

  const setSingleFilter = (keyValuePair) =>
    setFilters({
      ...filters,
      ...keyValuePair,
    });

  // This avoids having to use redux to store context values
  // since we can't use context to pass an object up to the modal
  useEffect(() => {
    if (updated) {
      setSingleFilter({ updated });
    }
  }, [updated]);

  return {
    filters,
    setFilters,
    setSingleFilter,
    ...asyncResponse,
  };
}

export const useSyncQSParams = ({
  setFilters,
  filters,
  qsParams,
  customDecode,
}) => {
  const [decodedParams, setDecodedParams] = useState({});
  const [hasLoadedPageWithQSParams, setHasLoadedPageWithQSParams] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const search = window.location.search.substring(1);

    const decodeQSFilters = (search) => {
      const defaultOptions = humps.camelizeKeys(
        Object.fromEntries(new URLSearchParams(search))
      );
      let obj = customDecode ? customDecode(defaultOptions) : defaultOptions;
      if (obj.page) {
        obj.page = +obj.page;
      }

      if (obj.pageSize) {
        obj.pageSize = +obj.pageSize;
      }

      if (obj.search) {
        obj.searchText = obj.search;
      }

      if ("search" in obj) {
        delete obj.search;
      }

      return obj;
    };

    if (!Object.keys(filters)?.length) {
      return {};
    }

    const decoded = decodeQSFilters(search);

    setDecodedParams(decoded);

    if (
      !R.equals(filters, decoded) &&
      !hasLoadedPageWithQSParams &&
      Object.keys(decoded)?.length
    ) {
      setFilters(decoded);
    }

    if (!R.equals(search, qsParams) && hasLoadedPageWithQSParams) {
      navigate({ search: qsParams, replace: true });
    }

    setHasLoadedPageWithQSParams(true);
  }, [qsParams]);

  return {
    decodedParams,
    hasLoadedPageWithQSParams,
  };
};
